import { Role } from "../enums/Role"
import { StatusCode } from "../enums/StatusCode"
import { IBusinessUnit } from "../interfaces/IBusinessUnit";
import { IGroupV2 } from "../interfaces/IGroup";

//** Base class in which all Response objects to derive from. */
export abstract class Response {
    public errorCode?: string;
    public body?: any;
    public errorMessage?: string;
    constructor(public status: StatusCode) {}
    setStatus(status: StatusCode): void { this.status = status}
    setErrorCode(errorCode: string): void { this.errorCode = errorCode}
    setErrorMessage(errorMessage: string): void { this.errorMessage = errorMessage}
    getErrorCode(): string { return this.errorCode || ''; }
    getErrorMessage(): string { return this.errorMessage || ''; }
}

//** Extension of the Response base class that provides API level Response Objects */
export class APIResponse extends Response {
    static SUCCESS: Response = new APIResponse(StatusCode.SUCCESS)
    static BAD_REQUEST: Response = new APIResponse(StatusCode.BAD_REQUEST)
    static CREATED: Response = new APIResponse(StatusCode.CREATED)
    static FORBIDDEN: Response = new APIResponse(StatusCode.FORBIDDEN)
    static INTERNAL_SERVER_ERROR: Response = new APIResponse(StatusCode.INTERNAL_SERVER_ERROR)
    static ACCEPTED: Response = new APIResponse(StatusCode.ACCEPTED)
    static NOT_FOUND: Response = new APIResponse(StatusCode.NOT_FOUND)
    static UNAUTHORIZED: Response = new APIResponse(StatusCode.UNAUTHORIZED)
    static StatusCodeMap: Record<StatusCode | number, APIResponse> = {
        200: APIResponse.SUCCESS,
        201: APIResponse.CREATED,
        202: APIResponse.ACCEPTED,
        400: APIResponse.BAD_REQUEST,
        401: APIResponse.UNAUTHORIZED,
        403: APIResponse.FORBIDDEN,
        404: APIResponse.NOT_FOUND,
        500: APIResponse.INTERNAL_SERVER_ERROR
    }

    /**
     * This method takes in a StatusCode or number as a parameter and returns the cooresponding APIResponse object or a BAD_REQUEST object when no mapping exist or the response in ot valid.
     * @param statusCode A valid HTTP Response Code or StatusCode Constant.
     * @returns An APIResponse object that cooresponds to the given status code.
     */
    public static TryMapStatusToReponseObject(statusCode: number | StatusCode) {
        try {
            //TODO: configure logger for project.
            //logger.error(`${Invalid response code from API:{response.status} Error: {error}}`);
            return this.StatusCodeMap[statusCode];
        } catch (error) {
            return APIResponse.BAD_REQUEST;
        }
    }
}

//** Extension of the Response base class that provides Authentication level Response Objects */
export class AuthResponse extends Response {
    constructor(
        public statusCode: StatusCode,
        private readonly userName: string,
        private readonly targetGroups: string[],
        private readonly accessToken: string,
        private readonly idToken: string,
        private readonly refreshToken: string,
        private readonly roles: Role[]) {
        super(statusCode)
    }
    
    GetTargetGroups(): string[] { return this.targetGroups; }
    GetUserName(): string { return this.userName; }
    GetIDToken(): string { return this.idToken; }
    GetAccessToken(): string { return this.accessToken; }
    GetRefreshToken(): string { return this.refreshToken; }
    GetRoles(): Role[] { return this.roles; }
}

export class AuthResponseV2 extends AuthResponse {
    constructor(
        public statusCode: StatusCode,
        userName: string,
        readonly id: string,
        targetGroups: string[],
        readonly businessUnits: IBusinessUnit[],
        readonly groups: IGroupV2[],
        accessToken: string,
        idToken: string,
        refreshToken: string,
        roles: Role[]) {
        super(
            statusCode,
            userName,
            targetGroups,
            accessToken,
            idToken,
            refreshToken,
            roles);
    }
    GetUserId(): string { return this.id; }
    GetBusinessUnits(): IBusinessUnit[] { return this.businessUnits; }
    GetGroups(): IGroupV2[] { return this.groups; }
}