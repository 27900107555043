export enum EventType {
    Open='open',
    Error='error',
    Close='close',
    ConfigUpdate='configUpdate',
    FlagUpdate='flagUpdate',
    PromptUpdate='promptUpdate',
    PromptUpload='promptUpload',
    HourUpdate='hourUpdate',
    HolidayUpdate='holidayUpdate',
    DispositionUpdate='dispositionUpdate',
    UserUpdate='userUpdate',
    ServerUpdate='serverUpdate',
    Ping='ping'
}