import { Form, message, Spin, Collapse, Tag, Divider  } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAppFocusedTargetGroup, GetAppFocusedTargetGroups, SetDrawerOpen, RemoveFromFocusedTargetGroups } from "../../app/appSlice";
import { CacheConfig, CacheFlags, CacheHolidays, CacheHours, CachePrompts, GetCachedConfigs, GetCachedFlags, GetCachedHolidays, GetCachedHours, GetCachedPrompts } from "../../app/cacheSlice";
import { GetUserIdToken } from "../../app/userSlice";
import { FormType } from "../../common/enums/FormType";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import CHCFormItem from "./CHCFormItem";
import { StatusCode } from "../../common/enums/StatusCode";

export interface CHCConfigFormProps {
    client: IRESTClient
    type: FormType
};

const { Panel } = Collapse;

const CHConfigForm = (props: CHCConfigFormProps) => {
    const {client, type} = props;
    const dispatch = useDispatch();
    const focusedTargetGroup = useSelector(GetAppFocusedTargetGroup);
    const ConfigsCache = useSelector(GetCachedConfigs);
    const FlagsCache = useSelector(GetCachedFlags);
    const PromptsCache = useSelector(GetCachedPrompts);
    const HoursCache = useSelector(GetCachedHours);
    const HolidaysCache = useSelector(GetCachedHolidays);
    const focusedTargetGroups = useSelector(GetAppFocusedTargetGroups);
    const idToken = useSelector(GetUserIdToken);
    const [loadingDating, SetLoadingData] = useState(false);
    let formItems: any[] = [];
    let FormCreationCallbacks: { [key in FormType]?: any} = {};
    let FormCompletionCallbacks: { [key in FormType]?: any} = {};

    /** Helper method to convert a moment into the proper time format for the database */
    const ConvertHours = (moments: any) => {
        for(let _moment in moments) {
            let value = moments[_moment];            
            //console.log("in here"+value)
            let convertedValue = []
            convertedValue.push(moment(value[0], 'llll').format('hh:mm a'));
            convertedValue.push(moment(value[1], 'llll').format('hh:mm a'));
            moments[_moment] = convertedValue;
        }
        return moments;
    }

    /** Helper method to convert a moment into the proper date format for the database. 
     * Additionally this method inverts the key value pairs such that the value becomes the key and the key become the value as it was originally.*/
    const ConvertDates = (holidays: any) => {
        let dateMap: any = {};
        let dateKey;
        for(let holiday in holidays) {
            dateKey = moment(holidays[holiday]).format('L').replaceAll('/', '');
            dateMap[dateKey] = holiday;
        }
        return dateMap;
    }

    /**
     * Takes in an array of moment strings and returns the database friendly equivalent.
     * @param hours Array of moment strings eg ["", ""]
     * @returns 
     */
    const ConvertHoursFromStringArray = (hours: string[]) => {
        let _hours = [];
        for(let index in hours) {
            let hour = hours[index];
            _hours.push(moment(hour, 'llll').format('hh:mm a'));
        }
        return _hours;
    }

    /**
     * Helper method to add a form item to the form
     * @param attribute { name : body }
     */
    const CreateFormItems = (attribute: any) => {
        let items = [];
        for(let key in attribute) {
            let value = attribute[key];
            items.push(<CHCFormItem client={client} key={key} itemKey={key} type={type} value={value}/>);
        }
        return items;
    }

    /**
     * This method takes in a list of focused target groups, and a reference to the cache. If the cache contains data for the focused target group
     * the data is iterated and a count of the occurence of each key and its distribution relative to the size of the group is used to find only keys
     * that exist in all of the currently selected groups.
     * @param focusedTargetGroups The currently focused target groups. This value is tracked in application state.
     * @param cache The cache in reference <Flags, Hours, Prompts, Holidays, etc>
     * @returns A dictionary containing only the common keys based on the data currently in the cache.
     */
    const ExtractCommonKeys = (focusedTargetGroups: string[], cache: any) => {
        // Create a map of the occurence that a particular key shows up in the list of selected target groups.
        let occurenceMap: Record<string, number> = {};
        focusedTargetGroups.forEach((targetGroup) => {
            if(cache[targetGroup] !== undefined) {                
                Object.keys(cache[targetGroup]).forEach(flagKey => {
                    let occurence = 1;
                    // If there is already an occurence of this in the list then we increment the occurences of this flag
                    if(occurenceMap[flagKey]!==undefined)
                        occurence = occurenceMap[flagKey] + 1;
                    // Set the number of occurence for this flag.
                    occurenceMap[flagKey] = occurence;
                });
            }
        });
        // Iterate the occurence map and append any flags that have an occurence count that is the same size of the focusedTargetGroups
        // indicating that this flag is evenly distributed among all groups.
        let commonFlags: Record<string, any> = {};
        focusedTargetGroups.forEach(targetGroup => {            
            let cachedData: any = cache[targetGroup];
            Object.keys(occurenceMap).forEach(occ => {
                if(occurenceMap[occ]===focusedTargetGroups.length)
                    commonFlags[occ] = cachedData[occ];
            });
        })        
        return commonFlags;
    }

    /** Form Completion Callbacks - Handles the completion of a Config type form */
    const OnConfigFormComplete = async (values: any) => {
        SetLoadingData(true);
        await client.SetDefaultConfig(focusedTargetGroup!, JSON.stringify(values), idToken!)
            .then((response) => {
            if(response.status===StatusCode.SUCCESS) {
                    message.success("Update Succeeded.");
                let cacheEntry = {'name': focusedTargetGroup, "body":response.body};
                    dispatch(CacheConfig(cacheEntry));
                    SetLoadingData(false);
                    return response;
                }
            })
        .catch((error)=>{ message.error("Update failed."); SetLoadingData(false); });
    };

    /** Make the Put call and cache the respones if the request was successfull. */
    const OnFlagsFormComplete = async (values: any) => {
        SetLoadingData(true);
        let requests = [];
        let newPrompts: {[key: string]: any} = {};
        let newFlags: {[key: string]: any} = {};
        for(let key in values) {
            /* eslint-disable no-template-curly-in-string */
            if(key.startsWith("${RELATIVE_PROMPT}")) {
                newPrompts[key.replaceAll("${RELATIVE_PROMPT}","")] = values[key];
            } else {
                newFlags[key] = values[key];
            }
        }
        if(idToken && focusedTargetGroup) {
            requests.push(client.SetDynamicFlag(focusedTargetGroup, JSON.stringify(newFlags), idToken));
            if(Object.entries(newPrompts).length> 0)
                requests.push(client.SetPrompt(focusedTargetGroup, 'undefined', JSON.stringify(newPrompts), idToken));

            Promise.all(requests).then(async (responses) => {
                let cacheEntry = {};
                if(responses[0].status===StatusCode.SUCCESS) {
                    message.success("Update Succeeded.");
                    // console.log(JSON.stringify(responses[0]))
                    let data = await client.GetDynamicFlags(focusedTargetGroup, idToken)
                    cacheEntry = { "name": focusedTargetGroup, "body" : data.body}
                    dispatch(CacheFlags(cacheEntry));
                }
                if(responses[1] && responses[1].status===StatusCode.SUCCESS) {
                    message.success("Update Succeeded.");
                    // console.log(JSON.stringify(responses[1]))
                    let _cacheEntry = { "name": focusedTargetGroup, "body" : responses[1].body }
                    dispatch(CachePrompts(_cacheEntry));
                }
                SetLoadingData(false);
                return responses;
            })
                .catch((error) => { message.error("Update failed."); SetLoadingData(false); });
        }
    };

    /**
     * The callback method for MultiSelect Flags Form types.
     * @param values The values from the form.
     * @returns 
     */
    const OnFlagsMultiFormComplete = async (values: any) => {
        SetLoadingData(true);
        if(Object.entries(values).length === 0){
            message.warning("No values changed");
            SetLoadingData(false);
            return;
        }       

        if (idToken && focusedTargetGroups && Object.keys(focusedTargetGroups).length > 1) {
            let requestMap: Record<string, any> = {};
            Object.keys(focusedTargetGroups).map((targetGroup) => {
                console.log(`Request for ${targetGroup} => ${JSON.stringify(values)}`);
                requestMap[targetGroup] = client.SetDynamicFlag(targetGroup, JSON.stringify(values), idToken);
            });

            Promise
            .all(Object.values(requestMap)).then(async (responses) => {
                let requestKeys = Object.keys(requestMap);
                for (let index = 0; index < responses.length; index++) {
                    const response = responses[index];
                    let targetGroupName = requestKeys[index];
                    console.log(`Response for ${targetGroupName} => ${JSON.stringify(response)}`);
                    if (response.status === StatusCode.SUCCESS) {                                               
                        let data = await client.GetDynamicFlags(targetGroupName, idToken);
                        let cacheEntry = {'name': targetGroupName, "body": data.body};
                        dispatch(CacheFlags(cacheEntry));
                    } else {
                        message.error(`Update for ${targetGroupName} failed.`);
                    }
                }
                 
                return responses;

            }).catch((error) => { message.error("Update failed."); SetLoadingData(false); });     
      
        };
        message.success("Update Succeeded");
        SetLoadingData(false);
    };

    /** Make the Put call and cache the respones if the request was successfull. */
    const OnPromptsMultiFormComplete = async (values: any) => {
        SetLoadingData(true);
        if(Object.entries(values).length === 0){
            message.warning("No values changed");
            SetLoadingData(false);
            return;
        }       
        let newPrompts: {[key: string]: any} = {};
        for(let key in values) {
            if(key.startsWith("${PROMPT}")) {
                newPrompts[key.replaceAll("${PROMPT}","")] = values[key];
            } else {
                newPrompts[key] = values[key];
            }
        }
        if (idToken && focusedTargetGroups && Object.keys(focusedTargetGroups).length > 1) {
            let requestMap: Record<string, any> = {};
            Object.keys(focusedTargetGroups).map((targetGroup) => {
                // console.log(`Request for ${targetGroup} => ${JSON.stringify(newPrompts)}`);
                requestMap[targetGroup] = client.SetPrompt(targetGroup, 'undefined', JSON.stringify(newPrompts), idToken);
            });

            Promise
            .all(Object.values(requestMap)).then(async (responses) => {
                let requestKeys = Object.keys(requestMap);
                for (let index = 0; index < responses.length; index++) {
                    const response = responses[index];
                    let targetGroupName = requestKeys[index];
                    // console.log(`Response for ${targetGroupName} => ${JSON.stringify(response)}`);
                    if (response.status === StatusCode.SUCCESS) {                                               
                        let data = await client.GetPrompts(targetGroupName, 'undefined', idToken);
                        let cacheEntry = {'name': targetGroupName, "body": data.body};
                        dispatch(CachePrompts(cacheEntry));
                    } else {
                        message.error(`Update for ${targetGroupName} failed.`);
                    }
                }
                 
                return responses;

            }).catch((error) => { message.error("Update failed."); SetLoadingData(false); });     
      
        };
        message.success("Update Succeeded");
        SetLoadingData(false);
    };

    /** Make the Put call and cache the respones if the request was successfull. */
    const OnPromptsFormComplete = async (values: any) => {
        SetLoadingData(true);
        let newPrompts: {[key: string]: any} = {};
        for(let key in values) {
            if(key.startsWith("${PROMPT}")) {
                newPrompts[key.replaceAll("${PROMPT}","")] = values[key];
            } else {
                newPrompts[key] = values[key];
            }
        }
        await client.SetPrompt(focusedTargetGroup!, 'undefined', JSON.stringify(newPrompts), idToken!)
            .then((response) => {
            if(response.status===StatusCode.SUCCESS) {
                    message.success("Update Succeeded.");
                    let cacheEntry = {'name': focusedTargetGroup, "body":response.body};
                    dispatch(CachePrompts(cacheEntry));
                }
                SetLoadingData(false);
                return response;
            })
            .catch((error) => { message.error("Update failed."); SetLoadingData(false); });
    };

    /** Make the Put call and cache the respones if the request was successfull. */
    const OnHoursMultiFormComplete = async (values: any) => {
        SetLoadingData(true);
        if(Object.entries(values).length === 0){
            message.warning("No values changed");
            SetLoadingData(false);
            return;
        }       
        
        if (idToken && focusedTargetGroups && Object.keys(focusedTargetGroups).length > 1) {
            let requestMap: Record<string, any> = {};
            let convertedValues = JSON.stringify(ConvertHours(values));
            Object.keys(focusedTargetGroups).map((targetGroup) => {
                // console.log(`Request for ${targetGroup} => ${JSON.stringify(values)}`);
                requestMap[targetGroup] = client.SetHours(targetGroup, convertedValues, idToken);
            });

            Promise
            .all(Object.values(requestMap)).then(async (responses) => {
                let cacheEntry = {};
                let requestKeys = Object.keys(requestMap);
                for (let index = 0; index < responses.length; index++) {
                    const response = responses[index];
                    let targetGroupName = requestKeys[index];
                    // console.log(`Response for ${targetGroupName} => ${JSON.stringify(response)}`);
                    if (response.status === StatusCode.SUCCESS) {                                               
                        let data = await client.GetHours(targetGroupName, idToken);
                        cacheEntry = { "name": targetGroupName, "body": data.body };
                        dispatch(CacheHours(cacheEntry));
                    } else {
                        message.error(`Update for ${targetGroupName} failed.`);
                    }
                }
                 
                return responses;

            }).catch((error) => { message.error("Update failed."); SetLoadingData(false); });     
      
        };
        message.success("Update Succeeded");
        SetLoadingData(false);
    }

    /** Make the Put call and cache the respones if the request was successfull. */
    const OnHoursFormComplete = async (values: any) => {
         SetLoadingData(true);
        await client.SetHours(focusedTargetGroup!, JSON.stringify(ConvertHours(values)), idToken!)
            .then((response) => {
            if(response.status===StatusCode.SUCCESS) {
                    message.success("Update Succeeded.");
                let cacheEntry = {'name': focusedTargetGroup, "body":response.body};
                    dispatch(CacheHours(cacheEntry));
                }
                SetLoadingData(false);
                return response;
            })
        .catch((error)=>{ message.error("Update failed."); SetLoadingData(false); });
    };

    /** Make the Put call and cache the respones if the request was successfull.*/
    const OnHolidaysFormComplete = async (values: any) => {
        SetLoadingData(true);
        let data: any = {};
        Object.entries(values).map((entry) => {
            let key = entry[0];
            let _value: any = entry[1];
            // console.log(`DEBUG 365: ${JSON.stringify(values)}`);
            if(key.endsWith('_value')) {
                let _key = moment(_value).format('L').replaceAll('/', '');
                if(!data[_key])
                    data[_key] = {};
                data[_key]['value'] = key.replaceAll('_value','');
                data[_key]['closed'] = values[key.replaceAll('_value','_isOpen')];
                data[_key]['hours'] = ConvertHoursFromStringArray(values[key.replaceAll('_value','_hours')]);
            }
        });

        await client.SetHolidays(focusedTargetGroup!, JSON.stringify(data), idToken!)
            .then((response) => {
            if(response.status===StatusCode.SUCCESS) {
                    message.success("Update Succeeded.");
                    dispatch(CacheHolidays({'name': focusedTargetGroup, "body":response.body}));
                }
                SetLoadingData(false);
                return response;
            })
        .catch((error)=>{ message.error("Update failed."); SetLoadingData(false); });
    };

    if(focusedTargetGroup) {
        // Set the Form Creation Callbacks
        FormCreationCallbacks[FormType.CONFIG] = () => { formItems = CreateFormItems(ConfigsCache[focusedTargetGroup]) };
        FormCreationCallbacks[FormType.FLAGS] = () => { formItems = CreateFormItems(FlagsCache[focusedTargetGroup]) };
        FormCreationCallbacks[FormType.HOLIDAYS] = () => { formItems = CreateFormItems(HolidaysCache[focusedTargetGroup]) };
        FormCreationCallbacks[FormType.HOURS] = () => { formItems = CreateFormItems(HoursCache[focusedTargetGroup]) };
        FormCreationCallbacks[FormType.PROMPTS] = () => { formItems = CreateFormItems(PromptsCache[focusedTargetGroup]) };
        if (focusedTargetGroups && Object.keys(focusedTargetGroups).length > 1) {            
            FormCreationCallbacks[FormType.MULTISELECT_FLAGS] = () => { formItems = CreateFormItems(ExtractCommonKeys(Object.keys(focusedTargetGroups), FlagsCache))};            
            FormCreationCallbacks[FormType.MULTISELECT_HOURS] = () => { formItems = CreateFormItems(ExtractCommonKeys(Object.keys(focusedTargetGroups), HoursCache))};
            FormCreationCallbacks[FormType.MULTISELECT_PROMPTS] = () => { formItems = CreateFormItems(ExtractCommonKeys(Object.keys(focusedTargetGroups), PromptsCache))};
        }

        // Create the form items based on the type of form that this is
        FormCreationCallbacks[type]();

        // Set the Form Completion Callbacks
        FormCompletionCallbacks[FormType.CONFIG] = async (values: any) => await OnConfigFormComplete(values);
        FormCompletionCallbacks[FormType.FLAGS] = async (values: any) => await OnFlagsFormComplete(values);
        FormCompletionCallbacks[FormType.HOLIDAYS] = async (values: any) => await OnHolidaysFormComplete(values);
        FormCompletionCallbacks[FormType.HOURS] = async (values: any) => await OnHoursFormComplete(values);
        FormCompletionCallbacks[FormType.PROMPTS] = async (values: any) => await OnPromptsFormComplete(values);
        FormCompletionCallbacks[FormType.MULTISELECT_HOURS] = async (values: any) => await OnHoursMultiFormComplete(values);
        FormCompletionCallbacks[FormType.MULTISELECT_FLAGS] = async (values: any) => await OnFlagsMultiFormComplete(values);
        FormCompletionCallbacks[FormType.MULTISELECT_PROMPTS] = async (values: any) => await OnPromptsMultiFormComplete(values);
    }

    // Set the api response handlers
    useEffect(() => {
        let ResponseCallbacks: { [key in FormType]?: any} = {};
        const requests: any[] = [];
        const requestOrder: FormType[] = [];
        ResponseCallbacks[FormType.CONFIG] = (cacheEntry: {}) => dispatch(CacheConfig(cacheEntry));
        ResponseCallbacks[FormType.FLAGS] = (cacheEntry: {}) => dispatch(CacheFlags(cacheEntry));
        ResponseCallbacks[FormType.PROMPTS] = (cacheEntry: {}) => dispatch(CachePrompts(cacheEntry));
        ResponseCallbacks[FormType.HOURS] = (cacheEntry: {}) => dispatch(CacheHours(cacheEntry));
        ResponseCallbacks[FormType.HOLIDAYS] = (cacheEntry: {}) => dispatch(CacheHolidays(cacheEntry));
        //Check the cache for the local queue configuration, if one is not found then append the request
        //to fetch the data to the list of requests. Adds the request type in order to a second list so the
        //responses can be handled properly.
        if(focusedTargetGroup && idToken) {            
            Object.keys(focusedTargetGroups).map((focusedTargetGroup) => {
            if(!FlagsCache[focusedTargetGroup]) {
                requests.push(client.GetDynamicFlags(focusedTargetGroup, idToken));
                requestOrder.push(FormType.FLAGS);
            }
            if(!PromptsCache[focusedTargetGroup]) {
                requests.push(client.GetPrompts(focusedTargetGroup, 'undefined', idToken));
                requestOrder.push(FormType.PROMPTS);
            }
            if(!HoursCache[focusedTargetGroup]) {
                requests.push(client.GetHours(focusedTargetGroup, idToken));
                requestOrder.push(FormType.HOURS);
            }
            if(!HolidaysCache[focusedTargetGroup]) {
                requests.push(client.GetHolidays(focusedTargetGroup, idToken));
                requestOrder.push(FormType.HOLIDAYS);
            }
            if(requests.length>0) {
                SetLoadingData(true);
                Promise.all(requests).then((responses) => { 
                    responses.forEach((response, index) => {
                    if (response.status===StatusCode.SUCCESS) {
                        let cacheEntry = {'name': focusedTargetGroup, "body":response.body}
                        ResponseCallbacks[requestOrder[index]](cacheEntry);
                    }
                });
                SetLoadingData(false);
            })
                    .catch((error) => message.error(`An error occured while loading the cache. ${error}`));
            }
        })
        }
    }, [FlagsCache, PromptsCache, HoursCache, HolidaysCache, focusedTargetGroup, idToken, client, focusedTargetGroups, dispatch]);

    /**
     * Wrapper method to invoke the appropriate callback for the given form type.
     * @param values The form values used to invoke the callback relative to this form type
     */
    const OnFinish = async (values: any) => {      
        // console.log(`Request: ${JSON.stringify(values)}`);
        try {
            await FormCompletionCallbacks[type](values);            
        } catch (error) {
            message.error(`Error: ${error}`);
        }
    }

    /**
     * Method to handle events when a tag is closed from the multi-select form
     * @param tag The tag that will be removed from the selected target groups.
     */
    const OnTagClose = (tag: string) => {
        if(focusedTargetGroups && Object.keys(focusedTargetGroups).length > 1){
            dispatch(RemoveFromFocusedTargetGroups(tag));
        }else{
            message.error(`Need more than one target group for bulk update`);
            dispatch(SetDrawerOpen(false));
        }        
    };

    return (
        <Spin spinning={loadingDating} tip="Loading data...">
            {focusedTargetGroups && Object.keys(focusedTargetGroups).length > 1 &&                 
                <>
                    <Collapse defaultActiveKey={['0']}>
                        <Panel header="Selected target groups" key="1">
                            {Object.keys(focusedTargetGroups).map(focusedTargetGroup => (
                                <Tag color="geekblue" key={focusedTargetGroup} closable onClose={() => OnTagClose(focusedTargetGroup)}>
                                    {focusedTargetGroup}
                                </Tag>
                            ))}
                        </Panel>
                    </Collapse>
                    <Divider/>
                </>}
            <Form
                colon
                onFinish={OnFinish}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                name={focusedTargetGroup}>
                {formItems}
            </Form> 
        </Spin>
    );
};

export default CHConfigForm;
