import { Button, Card, Divider, message } from "antd";
import { useSelector } from "react-redux";
import { GetUserIdToken, GetUserRoles } from "../../../../app/userSlice";
import { RESTClientWIP } from "../../../services/RESTClientWIP";
import { Role } from "../../../../common/enums/Role";
import { Response } from "../../../../common/models/Response";
import { useState } from "react";
import { StatusCode } from "../../../../common/enums/StatusCode";

export interface AddRoleToUserModalViewProps {
    client: RESTClientWIP,
    users: string[]
}

const AddRoleToUserModalView = (props: AddRoleToUserModalViewProps) => {
    const {client, users } = props;
    const ADMIN = process.env.REACT_APP_ADMINISTRATOR_ARN;
    const SUPER = process.env.REACT_APP_SUPERVISOR_ARN;
    const DEV = process.env.REACT_APP_DEVELOPER_ARN;
    const roles = useSelector(GetUserRoles);
    const token = useSelector(GetUserIdToken);
    const [loading, SetIsLoading] = useState(false);

    const OnAddRole = (role: string) => { 
        SetIsLoading(true);
        let requests: Promise<Response>[] = [];
        users.forEach(u => {
            console.log(u);
            requests.push(client.AddUserToRole(role, u, token!));
        });
        Promise.all(requests).then(response => {
            let hasError = false;
            response.forEach(response => {
                if(response.status !== StatusCode.SUCCESS) {
                    hasError = true;
                }
            });
            if (hasError) {
                message.error("Something went wrong when adding the role to the selected user(s). One or more requests failed!");
            } else {
                message.success("Role added to selected user(s)!")
            }
        })
        SetIsLoading(false);
     }

    return(
        <Card loading={loading}>
            <p>The CHC Self Service Portal is a role based self service application where users with a given role and set of permissions can regulate or grant access
                to other users so that they can manage the configuration of a given Contact Center/Queue configuration. Below is a list of available roles and a description of the capabilities of the roles.
            </p>
            <Divider/>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                <div style={{ display: 'flex', flexDirection: 'column', margin:'1%'}}>
                    <Card title='Supervisor'>
                        The SSP Supervisor role provides limited functionality within the portal and allows the following feature capabilities.
                        <ul>
                            <li>Toggle dynamic flags</li>
                            <li>View prompts associated with dynamic flags</li>
                            <li>View and modify Hours of Operation</li>
                            <li>View and modify Holiday Schedules</li>
                        </ul>
                    </Card>
                    <Button type='primary' onClick={() => OnAddRole(SUPER!)}>Add users(s) to role</Button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin:'1%'}}>
                    <Card title='Administrator'>
                        The SSP Adminsitrator role provides limited functionality within the portal and allows the following feature capabilities.
                            <ul>
                                <li>Toggle dynamic flags</li>
                                <li>View prompts associated with dynamic flags</li>
                                <li>View and modify Text and WAV dynamic prompts.</li>
                                <li>View and modify Hours of Operation</li>
                                <li>View and modify Holiday Schedules</li>
                                <li>View and modify select Standard Attribute Configuration</li>
                                <li>View Change Logs</li>
                                <li>Invite/Remove users to/from a Business Unit</li>
                                <li>Add/Remove users to/from a Business Unit Group ( Queue )</li>
                                <li>Add Administator Role to users.</li>
                            </ul>
                    </Card>
                    <Button type='primary'onClick={() => OnAddRole(ADMIN!)}>Add users(s) to role</Button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin:'1%'}}>
                    <Card title='Developer'>
                        The SSP Developer role provides unlimited functionality within the portal and allows the following feature capabilities.
                            <ul>
                            <li>Toggle dynamic flags</li>
                                <li>View prompts associated with dynamic flags</li>
                                <li>View and modify Text and WAV dynamic prompts.</li>
                                <li>View and modify Hours of Operation</li>
                                <li>View and modify Holiday Schedules</li>
                                <li>View and modify Standard Attribute Configuration</li>
                                <li>View Change Logs</li>
                                <li>Invite/Remove users to/from a Business Unit</li>
                                <li>Add/Remove users to/from a Business Unit Group ( Queue )</li>
                                <li>Add/Remove Administator Role to/from users.</li>
                                <li>Add/Remove Developer Role to/from users.</li>
                            </ul>
                    </Card>
                    <Button disabled={!roles?.includes(Role.DEVELOPER)} type='primary' onClick={() => OnAddRole(DEV!)}>Add users(s) to role</Button>
                </div>
            </div>
            <Divider/>
        </Card>
    );
}

export default AddRoleToUserModalView;