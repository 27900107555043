import { InfoCircleOutlined } from "@ant-design/icons";
import { Divider, message, notification } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import { GetAppPage, IsSiderCollapsed } from "../../app/appSlice";
import { InvalidateGroupsCache, InvalidateUsersCache } from "../../app/cacheSlice";
import { SetConnectionStatus } from "../../app/connectionSlice";
import { GetThemePrimaryColor, GetThemeSecondaryTextColor } from "../../app/themeSlice";
import { GetUserName, GetUserRoles, GetUserTargetGroups, SetUserTargetGroups } from "../../app/userSlice";
import { ApplicationPage } from "../../common/enums/ApplicationPage";
import { ConnectionStatus } from "../../common/enums/ConnectionStatus";
import { EventType } from "../../common/enums/EventType";
import { Role } from "../../common/enums/Role";
import { UserManagerOpCode } from "../../common/enums/UserManagerOpCode";
import { IEvent } from "../../common/interfaces/IEvent";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import CHCHSider from "./CHCSider";
import AccessLoggingPage from "./pages/AccessLoggingPage";
import AppSettingsPage from "./pages/AppSettingsPage";
import ErrorPage from "./pages/ErrorPage";
import LoadingPage from "./pages/LoadingPage";
import QueuesPage from "./pages/QueuesPage";
import UsersPage from "./pages/UsersPage";

export interface CHCContentProps {
    client: IRESTClient,
}

const CHCContent = (props: CHCContentProps) => {
    const {client } = props;
    let Callbacks: Record<EventType, (data:IEvent) => IEvent> = {
        [EventType.ConfigUpdate]: (data: IEvent) => OnConfigUpdate(data),
        [EventType.UserUpdate]: (data: IEvent) => OnUserUpdate(data),
        [EventType.Close]: (data: IEvent) => data,
        [EventType.Error]: (data: IEvent) => data,
        [EventType.Open]: (data: IEvent) => data,
        [EventType.ServerUpdate]: (data: IEvent) => data,
        [EventType.Ping]: (data: IEvent) => data,
        [EventType.FlagUpdate]: (data:IEvent) => data,
        [EventType.PromptUpdate]: (data:IEvent) => data,
        [EventType.PromptUpload]: (data:IEvent) => data,
        [EventType.HourUpdate]: (data:IEvent) => data,
        [EventType.HolidayUpdate]: (data:IEvent) => data,
        [EventType.DispositionUpdate]: (data:IEvent) => data
    };

    const dispatch = useDispatch();
    const userName = useSelector(GetUserName);
    const userGroups = useSelector(GetUserTargetGroups);
    const page = useSelector(GetAppPage);
    const siderCollapsed = useSelector(IsSiderCollapsed);
    const background = useSelector(GetThemePrimaryColor);
    const color = useSelector(GetThemeSecondaryTextColor);
    const roles = useSelector(GetUserRoles);
    const style: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        flex: '100 0'
    }

    const OnConfigUpdate = (data: IEvent): IEvent => {
        notification.info({
            duration: 10,
            icon: <InfoCircleOutlined />,
            message: `${data['target_group']}'s ${data['data_type']} have been updated!`
        });
        return data;
    }

    const OnUserUpdate = (data: IEvent): IEvent => {
        if (data.operation) {
            let operation: UserManagerOpCode = data.operation;
            let message = '';

            switch (operation) {
                case UserManagerOpCode.ADD_GROUP:
                    message = `${data.group_name} has been created!`
                    break;
                case UserManagerOpCode.DELETE_GROUP:
                    message = `${data.group_name} has been removed!`
                    break;
                case UserManagerOpCode.ADD_USER:
                    if (userName === data.user_name) {
                        let updatedGroups: (string | undefined)[] = [];
                        userGroups?.forEach(group => updatedGroups.push(group));
                        updatedGroups.push(data.group_name);
                        dispatch(SetUserTargetGroups(updatedGroups));
                        message = `You have been added to group: ${data.group_name}`;
                    } else {
                        message = `${data.user_name?.substring(0, data.user_name.indexOf('@'))} has been added to group: ${data.group_name}`;
                    }
                    break;
                case UserManagerOpCode.REMOVE_USER:
                    if (userName === data.user_name) {
                        let updatedGroups: (string | undefined)[] = [];
                        userGroups?.forEach(group => {
                            if (group !== data.group_name) {
                                updatedGroups?.push(group);
                            }
                        });
                        dispatch(SetUserTargetGroups(updatedGroups));
                        message = `You have been removed from group: ${data.group_name}`;
                    } else {
                        message = `${data.user_name?.substring(0, data.user_name.indexOf('@'))} has been removed from group: ${data.group_name}`;
                    }
                    break;
                default:
                    break;
            }

            if (roles?.includes(Role.ADMINISTRATOR) || roles?.includes(Role.DEVELOPER) || data.user_name === userName) {
                dispatch(InvalidateUsersCache());
                dispatch(InvalidateGroupsCache());
                notification.info({
                    duration: 10,
                    icon: <InfoCircleOutlined />,
                    message: message
                });
            }
        }
        return data;
    }

    const OnEventMessage = (data: any): IEvent => {
        try {
            let event: IEvent = JSON.parse(data.data);
            let eventType: EventType = event.event_type;
            return Callbacks[eventType](event);
        } catch {
            return data;
        }
    }

    // Configure websocket callbacks.
    useWebSocket(process.env.REACT_APP_WSS_API_BASE!, {
        onMessage: (e: any) => OnEventMessage(e),
        onOpen: (e: any) => { dispatch(SetConnectionStatus(ConnectionStatus.CONNECTED)); message.success(`Connected.`); },
        onClose: (e: any) => { dispatch(SetConnectionStatus(ConnectionStatus.DISCONNECTED)); message.error(`Disconnected.`) },
        onError: (e: any) => { message.error(JSON.stringify(e)); },
        shouldReconnect: (closeEvent) => process.env.NODE_ENV!=='development',
    });

    useEffect(() => { }, [color, background]);

    return (
        <div style={style}>
            {!siderCollapsed && <CHCHSider />}
            <div style={{ ...style, flexDirection: 'column', paddingLeft: 6, color: color }}>
                <Divider />
                <div style={{ ...style, justifyContent: 'space-evenly' }}>
                    {!Object.values(ApplicationPage).includes(page) && <ErrorPage errorCode={500} errorMessage="The page you are trying to view cannot be found or no longer exists." />}
                    {(roles?.includes(Role.ADMINISTRATOR) || roles?.includes(Role.DEVELOPER)) && page === ApplicationPage.USERS && <UsersPage client={client} />}
                    {page === ApplicationPage.LOGGING && <AccessLoggingPage client={client} />}
                    {page === ApplicationPage.QUEUES && <QueuesPage client={client} />}
                    {page === ApplicationPage.SETTINGS && <AppSettingsPage />}
                    {page === ApplicationPage.LOADING && <LoadingPage />}
                    {page === ApplicationPage.HELP && <ErrorPage errorMessage={"The help page is on its way! Check back soon."} />}
                    {page === ApplicationPage.ERROR && <ErrorPage errorMessage={"An error has occured."} />}
                </div>
            </div>
        </div>);
}

export default CHCContent;