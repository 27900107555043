import { BookOutlined, ProjectOutlined, QuestionOutlined, SettingOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetApplicationPage } from "../../app/appSlice";
import { GetThemePrimaryColor } from "../../app/themeSlice";
import { GetUserRoles, GetUserTargetGroups } from "../../app/userSlice";
import { ApplicationPage } from "../../common/enums/ApplicationPage";
import { Role } from "../../common/enums/Role";
import { GetCachedBusinessUnits } from "../../app/cacheSlice";

export interface CHCSiderProps {
}

export interface CHCButtonProps {
    icon?: any,
    onClick: () => any,
    label: string,
    type: "link" | "primary" | "dashed" | "ghost"
}

const CHCButton = (props: CHCButtonProps) => {
    const {icon, onClick, label, type} = props;
    const [hovered, OnHovered] = useState(false);
    const style: React.CSSProperties = {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row'
    }
    return(
        <div style={!hovered ? style :{...style, background: 'grey', borderRadius:4}} onMouseEnter={() => OnHovered(!hovered)} onMouseLeave={() => OnHovered(!hovered)}>
            <Button size='large' icon={icon} onClick={onClick} type={type}>{label}</Button>
        </div>);
}

const CHCHSider = (props: CHCSiderProps) => {
    const dispatch = useDispatch();
    const background = useSelector(GetThemePrimaryColor);
    const targetGroups = useSelector(GetUserTargetGroups);
    const businessUnits = useSelector(GetCachedBusinessUnits);
    const roles = useSelector(GetUserRoles);
    const style: React.CSSProperties = {
        background: background,
        borderRadius: 3,
        margin: 3,
        marginTop: 0,
        display: 'flex',
        flexDirection:'column'
    }
    const queuesLabel = `Queues (${targetGroups?.length})`
    const businessUnitsLabel = `Business Units (${Object.keys(businessUnits).length})`
    return(
        <div style={style}>
            <Divider/>
            <CHCButton icon={<ProjectOutlined />} label={queuesLabel} onClick={() => dispatch(SetApplicationPage(ApplicationPage.QUEUES))} type={"link"}/>
            {(roles?.includes(Role.ADMINISTRATOR) || roles?.includes(Role.DEVELOPER)) && <CHCButton icon={<TeamOutlined />} label={businessUnitsLabel} onClick={() => dispatch(SetApplicationPage(ApplicationPage.USERS))} type={"link"}/>}
            {(roles?.includes(Role.ADMINISTRATOR) || roles?.includes(Role.DEVELOPER)) && <CHCButton icon={<BookOutlined />} label='Logging' onClick={() => dispatch(SetApplicationPage(ApplicationPage.LOGGING))} type={"link"}/>}
            <CHCButton icon={<SettingOutlined />} label='App Settings' onClick={() => dispatch(SetApplicationPage(ApplicationPage.SETTINGS))} type={"link"}/>
            <CHCButton icon={<QuestionOutlined />} label='Help' onClick={() => dispatch(SetApplicationPage(ApplicationPage.HELP))} type={"link"}/>
            <Divider/>
        </div>);
}

export default CHCHSider;