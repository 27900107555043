import { Button, Card, Form, message } from "antd";
import { RESTClientWIP } from "../../../services/RESTClientWIP";
import Input from "antd/lib/input/Input";
import { useDispatch, useSelector } from "react-redux";
import { GetUserIdToken } from "../../../../app/userSlice";
import { StatusCode } from "../../../../common/enums/StatusCode";
import { SetBuModalIsVisible, SetBuModalView } from "../../../../app/appSlice";

export interface CreateGroupModalViewProps {
    client: RESTClientWIP,
    businessUnitId: string
}

const CreateGroupModalView = (props: CreateGroupModalViewProps) => {
    let { client, businessUnitId } = props;
    const idToken = useSelector(GetUserIdToken);
    const dispatch = useDispatch();

    const OnFormComplete = async (values: any) => {
        if(idToken)  {
           let response = await client.CreateGroup(values, idToken!, businessUnitId);
           if(response.status === StatusCode.SUCCESS) {
                message.success("Group Created!");
                dispatch(SetBuModalView(undefined));
                dispatch(SetBuModalIsVisible(false));
           } else {
                message.error("Group Creation Failed!");
           }
        } else {
            message.error("Invalid or expired token!")
        }
    }

    return(
        <Card title="Create New Business Unit">
            <Form 
                colon
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={OnFormComplete}
                name="CreateGroupForm">
                <Form.Item key='name' required label="Name" name="name">
                    <Input required></Input>
                </Form.Item>

                <Form.Item key='description' required label="description" name="description">
                    <Input required></Input>
                </Form.Item>

                <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                    <Button key='createGroupComplete' htmlType='submit' type='primary'>Create</Button>
                </div>

            </Form>
        </Card>
    );
}

export default CreateGroupModalView;