import { WarningOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { GetThemeBackground, GetThemePrimaryColor, GetThemeSecondaryTextColor } from "../../../app/themeSlice";

export interface ErrorPageProps {
    errorCode?: number
    errorMessage: string
}

//** This component takes in an errorCode and Message */
const ErrorPage = (props: ErrorPageProps) => {
    const {errorCode, errorMessage} = props;
    const style: React.CSSProperties = {
        display: 'flex',
        flexDirection:'row',
        justifyContent:'space-evenly',
        textAlign: 'center',
        background: useSelector(GetThemeBackground),
        color: useSelector(GetThemeSecondaryTextColor)
    }

    return(
        <div style={style}>
            <div style={{...style, flexDirection:'column', justifyContent:'center', minHeight:'100vh'}}>
                <div style={{fontSize:'72px', color: useSelector(GetThemePrimaryColor)}}><WarningOutlined/></div>
                {errorCode && <div>{errorCode}: {errorMessage}</div>}
                {!errorCode && <div>{errorMessage}</div>}
            </div>
        </div>);
}

export default ErrorPage;