import { CheckOutlined, DownloadOutlined, EditOutlined, QuestionOutlined, StopOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Switch, DatePicker, TimePicker, Input, Slider, Select, Button, message, UploadProps, Tooltip, Divider, Upload, Menu, } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadFileStatus } from "antd/lib/upload/interface";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { GetAppFocusedTargetGroup } from "../../app/appSlice";
import { GetCachedPrompts } from "../../app/cacheSlice";
import { GetUserIdToken, GetUserRoles } from "../../app/userSlice";
import { FormType } from "../../common/enums/FormType";
import { Language } from "../../common/enums/Language";
import { Role } from "../../common/enums/Role";
import { StandardAttributes } from "../../common/enums/StandardAttributes";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import { Holiday } from "../../common/models/Holiday";
import ErrorPage from "./pages/ErrorPage";
import { StatusCode } from "../../common/enums/StatusCode";
const { Option } = Select;

export interface CHCFormItemProps {
    client: IRESTClient,
    itemKey: string,
    type: FormType,
    value: any
};

const CHCFormItem = (props: CHCFormItemProps) => {
    const { itemKey, type, value, client } = props;
    const roles = useSelector(GetUserRoles);
    const focusedTargetGroup = useSelector(GetAppFocusedTargetGroup);
    const idToken = useSelector(GetUserIdToken);
    const RelevantPromptsCache = useSelector(GetCachedPrompts)[focusedTargetGroup!];
    let formCallbacks: { [key in FormType]?: any } = {};

    /**
     * 
     * @param file Helper method to convert the wave file to base64.
     * @returns 
     */
    function GetBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /**
     * Invoked when the value passed to the component is of type Prompt
     * @param itemKey The original key for the item.
     * @param value The value to be used as the default value
     * @param roles The users associated roles
     * @returns A JSX Form Item Element that displays this type of value in the correct format.
     */
    const OnPromptsFormItem = (itemKey: string, value: any, roles: Role[]) => {
        let label = `${itemKey.charAt(0).toUpperCase()}${itemKey.slice(1)}`
        let admin = roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let valueString = JSON.stringify(value);
        let [editEnabled, SetEditEnabled] = useState<boolean>(false);
        let uploadCallbacks: Record<UploadFileStatus, any> = {
            done: () => message.success('Upload complete!'),
            error: () => message.error('Upload failed!'),
            success: () => message.success('Upload complete!'),
            uploading: () => message.info('Uploading...'),
            removed: () => message.success('Removed')
        }
        // Wave file handling.
        if (valueString.startsWith("\"s3://") || valueString.startsWith("\"https://") || valueString.startsWith("\"http://")) {
            let valueArray = valueString.split('/');
            let _value = valueArray[valueArray.length - 1].replaceAll("\"", "");
            let payload: {[key: string]: any} = {};
            let uploadProps: UploadProps = {
                accept: ".wav",
                name: _value,
                showUploadList: false,
                onChange(info) {
                    if (info.file.status)
                        uploadCallbacks[info.file.status]();
                },
                async beforeUpload(file, FileList) {
                    await GetBase64(file).then(async data =>{
                        payload[itemKey] = data;
                        await client.UploadPromptWav(focusedTargetGroup!, 'undefined', payload, idToken!).then((apiResponse) => {
                            if (apiResponse.status !== undefined && apiResponse.status === StatusCode.SUCCESS) {
                                message.success('Upload complete!');
                            } else {
                                let errorMessage = apiResponse.errorMessage;
                                message.error(`Upload Failed! ${errorMessage === undefined ? '': errorMessage}`);
                            }
                        })
                    });
                    return false;
                },
            }
            return (
                <div style={{display:'flex', flexDirection:'column'}}>
                    <Divider orientation="left">{label}</Divider>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                        <Upload {...uploadProps}>
                            <Button disabled={!admin} icon={<UploadOutlined />} type="link">Upload</Button>
                        </Upload>
                        <Button disabled type="link" icon={<DownloadOutlined />}>Download</Button>
                    </div>
                    <div>
                        {'S3 Url: '}
                        <TextArea autoSize={{maxRows:1}} defaultValue={value} disabled/>
                    </div>
                    <Divider/>
                </div>);
        }
        return (
            <>
                <Divider orientation="left">{label}</Divider>
                <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{flex:'2'}}>
                    {editEnabled &&
                        <Form.Item 
                        labelAlign={'left'}
                        labelCol={{span:7}}
                        key={itemKey + 'prompt'}
                        initialValue={value} label={'Prompt'}
                        name={"${PROMPT}" + itemKey}>
                            <TextArea autoSize={{minRows:3, maxRows:8}} defaultValue={value} disabled={!admin || !editEnabled} />
                        </Form.Item>}
                    {!editEnabled &&
                        <div>
                            {'Prompt: '}
                            <TextArea autoSize={{minRows:3, maxRows:8}} defaultValue={value} disabled={!admin || !editEnabled} />
                        </div>}
                </div>
                <div style={{flex:'1'}}>
                    <Menu mode="horizontal" disabledOverflow={true}>
                        {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                                <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                            </Menu.Item>}
                        {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                                <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                            </Menu.Item>}
                        {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                            <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                            </Menu.Item>}
                    </Menu>
                </div>
            </div>
            <Divider/>
        </>);
    }

    /**
     * Invoked when the value passed to the component is of type Holidays
     * @param itemKey The original key for the item.
     * @param value The value to be used as the default value
     * @param roles The users associated roles
     * @returns A JSX Form Item Element that displays this type of value in the correct format.
     */
    const OnHolidaysFormItem = (itemKey: string, value: any, roles: Role[]) => {
        let supervisor = roles?.includes(Role.SUPERVISOR) || roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let [editEnabled, SetEditEnabled] = useState<boolean>(false);
        let holiday: Holiday = value;
        let [closed, ToggleOpenClose] = useState<boolean>(holiday.closed);
        let tooltipMessage = closed? `Set Closed to false to enable special hours of operation for this holiday.` : `Set Closed to true to disable special hours of operation for this holiday.`;
        return(
            <>
                <Menu style={{float:'right'}} mode="horizontal" disabledOverflow={true}>
                    {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                            <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                        </Menu.Item>}
                    {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                            <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                        </Menu.Item>}
                    {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                        <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                        </Menu.Item>}
                </Menu>
                <Divider orientation="left">{holiday.value}</Divider>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:'2'}}>
                        {!editEnabled && 
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div style={{flex:'1'}}>Date:</div>
                                    <div style={{flex:'6'}}>
                                        <DatePicker 
                                            defaultValue={moment(itemKey, 'MM/DD/YYYY')} format={"MM/DD/YYYY"}
                                            size="large" allowClear={false} disabled={!supervisor || !editEnabled} />
                                    </div>
                                </div><br/>

                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div style={{flex:'1'}}>Closed:</div>
                                    <div style={{flex:'6'}}>
                                        <Switch
                                            defaultChecked={holiday.closed} disabled={!supervisor || !editEnabled}
                                            checkedChildren={'True'} unCheckedChildren={'False'} />
                                    </div>
                                </div><br/>
                                
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div style={{flex:'1'}}>Hours:</div>
                                    <div style={{flex:'6'}}>
                                        <TimePicker.RangePicker
                                            defaultValue={[moment(holiday.hours[0], 'h:mm a'), moment(holiday.hours[1], 'h:mm a')]}
                                            format="h:mm a" size="large" allowClear={false} disabled={!supervisor || !editEnabled} />
                                    </div>
                                </div><br/>
                            </div>}

                        {editEnabled &&
                            <>
                                <Form.Item
                                labelAlign={'left'}
                                labelCol={{span:4}}
                                key={itemKey + 'holidays'}
                                initialValue={moment(itemKey, 'MM/DD/YYYY')} 
                                label={'Date'}
                                name={`${holiday.value}`+'_value'}>
                                    <DatePicker format={"MM/DD/YYYY"} size="large" allowClear={false} disabled={!supervisor || !editEnabled} />
                                </Form.Item>

                                <Form.Item
                                labelAlign={'left'}
                                labelCol={{span:4}}
                                key={itemKey + 'closed'}
                                initialValue={holiday.closed}
                                label={'Closed'}
                                valuePropName='checked' 
                                name={`${holiday.value}`+'_isOpen'}>
                                    <Switch 
                                    onChange={() => ToggleOpenClose(!closed)}
                                    defaultChecked={holiday.closed} disabled={!supervisor || !editEnabled} 
                                    checkedChildren={'True'} unCheckedChildren={'False'} />
                                </Form.Item>
                                
                                <Form.Item
                                tooltip={tooltipMessage}
                                labelAlign={'left'}
                                labelCol={{span:4}}
                                key={itemKey + '_hours'}
                                initialValue={[moment(holiday.hours[0], 'h:mm a'), moment(holiday.hours[1], 'h:mm a')]}
                                label={'Hours'}
                                name={`${holiday.value}`+'_hours'}>
                                    <TimePicker.RangePicker 
                                    format="h:mm a"
                                    size="large"
                                    allowClear={false} disabled={!supervisor || !editEnabled || closed} />
                                </Form.Item>
                            </>}
                    </div>
                </div>
                <Divider/>
            </>);
    }

    /**
     * Invoked when the value passed to the component is of type Hours
     * @param itemKey The original key for the item.
     * @param value The value to be used as the default value
     * @param roles The users associated roles
     * @returns A JSX Form Item Element that displays this type of value in the correct format.
     */
    const OnHoursFormItem = (itemKey: string, value: any, roles: Role[]) => {
        let label = `${itemKey.charAt(0).toUpperCase()}${itemKey.slice(1)}`
        let supervisor = roles?.includes(Role.SUPERVISOR) || roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let [editEnabled, SetEditEnabled] = useState<boolean>(false);
        return (
            <>
                <Divider orientation="left">{label}</Divider>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:'2'}}>
                        {editEnabled &&
                            <Form.Item
                            labelAlign={'left'}
                            labelCol={{span:7}}
                            key={itemKey + 'hours'}
                            initialValue={[moment(value[0], 'h:mm a'), moment(value[1], 'h:mm a')]}
                            label={'Hours:'} name={itemKey}>
                                <TimePicker.RangePicker format="h:mm a" size="large" allowClear={false} disabled={!supervisor || !editEnabled} />
                            </Form.Item>}
                        {!editEnabled &&
                            <div>
                                {'Hours: '}<TimePicker.RangePicker 
                                    defaultValue={[moment(value[0], 'h:mm a'), moment(value[1], 'h:mm a')]}
                                    format="h:mm a" size="large" allowClear={false} disabled={!supervisor || !editEnabled} />
                            </div>}
                    </div>
                    <div style={{flex:'1'}}>
                        <Menu mode="horizontal" disabledOverflow={true}>
                            {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                                    <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                                </Menu.Item>}
                            {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                                    <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                                </Menu.Item>}
                            {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                                <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                                </Menu.Item>}
                        </Menu>
                    </div>
                </div>
            <Divider/>
        </>);
    }

    /**
     * Invoked when the value passed to the component is of type Flags
     * @param itemKey The original key for the item.
     * @param value The value to be used as the default value
     * @param roles The users associated roles
     * @returns A JSX Form Item Element that displays this type of value in the correct format.
     */
    const OnFlagsFormItem = (itemKey: string, value: any, roles: Role[]) => {
        let label = `${itemKey.charAt(0).toUpperCase()}${itemKey.slice(1)}`;
        let admin = roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let supervisor = roles?.includes(Role.SUPERVISOR) || roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let [editEnabled, SetEditEnabled] = useState<boolean>(false);

        // Disable rendering of the API toggle for non developers.
        if(itemKey===StandardAttributes.CONNECT_API_ENABLED && !roles?.includes(Role.DEVELOPER))
            return;

        if (RelevantPromptsCache[value['relativePrompt']]) {
            let relativePrompt = RelevantPromptsCache[value['relativePrompt']];
            return (
                <>
                    <Divider orientation="left">{label}</Divider>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div style={{flex:'2'}}>
                            {editEnabled &&
                                <>
                                    <Form.Item 
                                        labelAlign={'left'}
                                        labelCol={{span:7}}
                                        key={itemKey + 'flag'}
                                        initialValue={value['value']}
                                        valuePropName='checked'
                                        label={'Enabled'} name={itemKey}>
                                        <Switch disabled={!supervisor || !editEnabled} checkedChildren={'True'} unCheckedChildren={'False'} />
                                    </Form.Item>
                                    <Form.Item 
                                    tooltip='Any prompt that is effected by the toggle of this feature will be displayed here.'
                                    label='Prompt'
                                    labelAlign={'left'}
                                    labelCol={{span:7}}
                                    key={itemKey + 'relative-prompt'}
                                    initialValue={relativePrompt}
                                    name={"${RELATIVE_PROMPT}" + itemKey}>
                                        <TextArea autoSize={{minRows:3, maxRows:8}} defaultValue={relativePrompt} disabled={!admin || !editEnabled} />
                                    </Form.Item>
                                </>}

                            {!editEnabled &&
                                <>
                                    <div style={{ flex: '1', margin: `1%`, marginRight: 0 }}>
                                        {'Enabled: '}
                                        <Switch defaultChecked={value['value']} disabled={!supervisor || !editEnabled} checkedChildren={'True'} unCheckedChildren={'False'} />
                                    </div>
                                    <div style={{ flex: '2', paddingRight: `1%` }}>
                                        {'Prompt: '}
                                        <TextArea autoSize={{minRows:3, maxRows:8}} defaultValue={relativePrompt} disabled={!admin || !editEnabled} />
                                    </div>
                                </>}
                        </div>
                        <div style={{flex:'1'}}>
                            <Menu mode="horizontal" disabledOverflow={true}>
                                <Menu.Item><Tooltip overlay={value['description'] || 'Toggle this feature flag'}><QuestionOutlined/></Tooltip></Menu.Item>
                                {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                                        <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                                    </Menu.Item>}
                                {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                                        <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                                    </Menu.Item>}
                                {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                                    <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                                    </Menu.Item>}
                            </Menu>
                        </div>
                    </div>
                    <Divider/>
                </>);
        }
        return (
            <>
                <Divider orientation="left">{label}</Divider>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                    <Menu mode="horizontal" disabledOverflow={true}>
                            <Menu.Item><Tooltip overlay={value['description'] || 'Toggle this feature flag'}><QuestionOutlined/></Tooltip></Menu.Item>
                            {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                                    <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                                </Menu.Item>}
                            {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                                    <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                                </Menu.Item>}
                            {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                                <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                                </Menu.Item>}
                        </Menu>
                    </div>
                    {editEnabled && 
                        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                            {'Enabled: '}
                            <Form.Item 
                            key={itemKey + 'flag'}
                            initialValue={value['value']}
                            valuePropName='checked' name={itemKey}>
                                <Switch defaultChecked={value['value']} disabled={!supervisor || !editEnabled} checkedChildren={'True'} unCheckedChildren={'False'} />
                            </Form.Item>
                        </div>}
                        
                    {!editEnabled && 
                        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                            {'Enabled: '}<Switch 
                            defaultChecked={value['value']} 
                            disabled={!supervisor || !editEnabled} 
                            checkedChildren={'True'} unCheckedChildren={'False'} />
                        </div>}
                    <Divider />
                </div>
                <Divider/>
            </>);
    }

    /**
     * Invoked when the value passed to the component is of type Flags with MultiSelect
     * @param itemKey The original key for the item.
     * @param value The value to be used as the default value
     * @param roles The users associated roles
     * @returns A JSX Form Item Element that displays this type of value in the correct format.
     */
     const OnFlagsMultiFormItem = (itemKey: string, value: any, roles: Role[]) => {
        let label = `${itemKey.charAt(0).toUpperCase()}${itemKey.slice(1)}`;
        let description = <Tooltip overlay={value['description'] || 'Enable or disable.'}>{label}</Tooltip>
        let supervisor = roles?.includes(Role.SUPERVISOR) || roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let [editEnabled, SetEditEnabled] = useState<boolean>(false);
        // Disable rendering of the API toggle for non developers.
        if(itemKey===StandardAttributes.CONNECT_API_ENABLED && !roles?.includes(Role.DEVELOPER))
            return;
        return (
            <>
            <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{flex:'1'}}>
                    {editEnabled && 
                        <Form.Item 
                        labelAlign={'left'}
                        labelCol={{span:7}}
                        key={itemKey + 'flag'}
                        initialValue={value['value']}
                        label={description} valuePropName='checked'
                        name={itemKey}>
                            <Switch defaultChecked={value['value']} disabled={!supervisor || !editEnabled} checkedChildren={'True'} unCheckedChildren={'False'} />
                        </Form.Item>}
                        
                    {!editEnabled && 
                        <div>
                            {label}:{`\t`}
                            <Switch defaultChecked={value['value']} disabled={!supervisor || !editEnabled} checkedChildren={'True'} unCheckedChildren={'False'} />
                        </div>}
                </div>
                <div style={{flex:'1'}}>
                    <Menu mode="horizontal" disabledOverflow={true}>
                        <Menu.Item><Tooltip overlay={value['description'] || 'Toggle this feature flag'}><QuestionOutlined/></Tooltip></Menu.Item>
                        {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                                <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                            </Menu.Item>}
                        {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                                <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                            </Menu.Item>}
                        {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                            <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                            </Menu.Item>}
                    </Menu>
                </div>
            </div>
            <Divider/>
        </>);
    }

    /**
     * Invoked when the value passed to the component is of type Config
     * @param itemKey The original key for the item.
     * @param value The value to be used as the default value
     * @param roles The users associated roles
     * @returns A JSX Form Item Element that displays this type of value in the correct format.
     */
    const OnConfigFormItem = (itemKey: string, value: any, roles: Role[]) => {
        let label = `${itemKey.charAt(0).toUpperCase()}${itemKey.slice(1)}`
        let admin = roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR);
        let [editEnabled, SetEditEnabled] = useState<boolean>(false);
        return(
            <>
                <Divider orientation="left">{label}</Divider>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:'2'}}>
                        {itemKey === StandardAttributes.CALLBACK_OFFSET_HOURS &&
                            <>
                                {editEnabled?
                                    <Form.Item tooltip={"Set the difference in hours between when a callback queue is closed based on the parent queues original closure time. EG. An offset of .5 will close callback queus 30 minutes before the parent queues closing time."} key={itemKey + 'config'} initialValue={value} label={'Value'} name={itemKey}>
                                        <Slider value={value} max={3} min={0} disabled={!admin || !editEnabled} step={0.5}/>
                                    </Form.Item> : 
                                    <div>
                                        {'Value: '}
                                        <Slider value={value} max={3} min={0} disabled={!admin || !editEnabled} step={0.5}/>
                                    </div>}
                            </>}
                        {itemKey === StandardAttributes.BOT_PERCENTAGE_ALLOCATION &&
                            <>
                                {editEnabled?
                                    <Form.Item key={itemKey + 'config'} initialValue={value} label={'Value'} name={itemKey}>
                                        <Slider value={value} max={100} min={0} disabled={!admin || !editEnabled} />
                                    </Form.Item> : 
                                    <div>
                                        {'Value: '}
                                        <Slider value={value} max={100} min={0} disabled={!admin || !editEnabled} />
                                    </div>}
                            </>}
                        {itemKey === StandardAttributes.LANGUAGE &&
                            <>
                                {editEnabled?
                                    <Form.Item 
                                        key={itemKey + 'config'} initialValue={value} label={'Value'} name={itemKey}>
                                        <Select defaultValue={value} style={{ width: 220 }} disabled={!admin || !editEnabled}>
                                            {Object.keys(Language).map( key => {
                                               return process.env.REACT_APP_LANGUAGES?.split(', ').includes(key) ? <Option value={key}>{key}</Option> : <Option disabled value={key}>{key}</Option>
                                            })}
                                        </Select>
                                    </Form.Item> :
                                    <div>
                                        {'Value: '}
                                        <Select defaultValue={value} style={{ width: 220 }} disabled={!admin || !editEnabled}>
                                            {Object.keys(Language).map( key =><Option value={key}>{key}</Option> )}                                         
                                        </Select>
                                    </div>}
                            </>}
                        {itemKey === StandardAttributes.BOT_RETURNED_CALL_PRIORITY &&
                            <>
                            {editEnabled?
                                <Form.Item key={itemKey + 'config'} initialValue={'Value'} label={label} name={itemKey}>
                                    <Select defaultValue={value} style={{ width: 220 }} disabled={!admin || !editEnabled}>
                                        <Option value="default">Default</Option>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </Form.Item> :
                            <div>
                                {'Value: '}
                                <Select defaultValue={value} style={{ width: 220 }} disabled={!admin || !editEnabled}>
                                    <Option value="default">Default</Option>
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                </Select>
                            </div>}
                            </>}
                        {itemKey !== StandardAttributes.BOT_RETURNED_CALL_PRIORITY && itemKey !== StandardAttributes.LANGUAGE && itemKey !== StandardAttributes.BOT_PERCENTAGE_ALLOCATION && itemKey!== StandardAttributes.CALLBACK_OFFSET_HOURS &&
                        <>
                            {editEnabled?
                            <Form.Item key={itemKey + 'Item'} initialValue={value} label={'Value'} name={itemKey}>
                                <Input defaultValue={value} disabled={!roles?.includes(Role.DEVELOPER) || !editEnabled} />
                            </Form.Item> :
                            <div>
                                {'Value: '}<Input defaultValue={value} disabled={!roles?.includes(Role.DEVELOPER) || !editEnabled} />
                            </div>
                            }
                        </>}
                        </div>
                    <div style={{flex:'1'}}>
                        <Menu mode="horizontal" disabledOverflow={true}>
                            {editEnabled && <Menu.Item title="Save your changes to this value." key='saveButtonMI' icon={<CheckOutlined />}>
                                    <Button key='saveButton' type='link' htmlType='submit'>Save</Button>
                                </Menu.Item>}
                            {!editEnabled && <Menu.Item title="Edit this value" key='editButtonMI' icon={<EditOutlined />}>
                                    <Button key='editButton' type="link" onClick={() => SetEditEnabled(true)}>Edit</Button>
                                </Menu.Item>}
                            {editEnabled && <Menu.Item title="Cancel your changes to this value." key='cancelButtonMI' icon={<StopOutlined />}>
                                <Button key='cancelButton' danger type="link" onClick={() => SetEditEnabled(false)}>Cancel</Button>
                                </Menu.Item>}
                        </Menu>
                    </div>
                </div>
                <Divider/>
            </>);
    }

    formCallbacks[FormType.CONFIG] = OnConfigFormItem;
    formCallbacks[FormType.PROMPTS] = OnPromptsFormItem;
    formCallbacks[FormType.HOLIDAYS] = OnHolidaysFormItem;
    formCallbacks[FormType.HOURS] = OnHoursFormItem;
    formCallbacks[FormType.FLAGS] = OnFlagsFormItem;
    formCallbacks[FormType.MULTISELECT_FLAGS] = OnFlagsMultiFormItem;
    formCallbacks[FormType.MULTISELECT_HOURS] = OnHoursFormItem;
    formCallbacks[FormType.MULTISELECT_PROMPTS] = OnPromptsFormItem;

    try {
        return formCallbacks[type](itemKey, value, roles);
    } catch (error) {
        return(<><ErrorPage errorMessage="Unsupported form type!"/></>);
    }

};

export default CHCFormItem;