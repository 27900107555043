import { createSlice } from '@reduxjs/toolkit';
import { Theme } from '../common/enums/Theme';
import { Themes } from '../common/models/Theme';
import { RootState } from './store';

export interface ThemeState {
  currentTheme: Theme,
  background: string,
  textColor?: string,
  secondaryTextColor?: string,
  linkColor?: string,
  primaryColor: string,
  secondaryColor?: string
}

const initialState: ThemeState = {
  currentTheme: Theme.DEFAULT,
  background: Themes.DefaultTheme.background,
  textColor: Themes.DefaultTheme.textColor,
  secondaryTextColor: Themes.DefaultTheme.secondaryTextColor,
  linkColor: Themes.DefaultTheme.linkColor,
  primaryColor: Themes.DefaultTheme.primaryColor,
  secondaryColor: Themes.DefaultTheme.secondaryColor
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    SetTheme: (state, action) => { state.currentTheme = action.payload; },
    SetThemeBackground: (state, action) => { state.background = action.payload; },
    SetThemeTextColor: (state, action) => { state.textColor = action.payload; },
    SetSecondaryTextColor: (state, action) => { state.secondaryTextColor = action.payload; },
    SetThemeLinkColor: (state, action) => { state.linkColor = action.payload; },
    SetThemePrimaryColor: (state, action) => { state.primaryColor = action.payload; },
    SetThemeSecondaryColor: (state, action) => { state.secondaryColor = action.payload; }
  },
});

export const GetCurrentTheme = (state: RootState) => state.theme.currentTheme;
export const GetThemeSecondaryTextColor = (state: RootState) => state.theme.secondaryTextColor;
export const GetThemeBackground = (state: RootState) => state.theme.background;
export const GetThemeTextColor = (state: RootState) => state.theme.textColor;
export const GetThemeLinkColor = (state: RootState) => state.theme.linkColor;
export const GetThemePrimaryColor = (state: RootState) => state.theme.primaryColor;
export const GetThemeSecondaryColor = (state: RootState) => state.theme.secondaryColor;

export const {
  SetTheme,
  SetSecondaryTextColor,
  SetThemeBackground,
  SetThemeTextColor,
  SetThemeLinkColor,
  SetThemePrimaryColor,
  SetThemeSecondaryColor
} = themeSlice.actions;

export default themeSlice.reducer;
