import { Button, Card, Form, Input, InputRef, Menu, MenuProps, message, Space, Table, Tag, Tooltip, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ColumnsType, ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { GetUserIdToken } from "../../../app/userSlice";
import { EventType } from "../../../common/enums/EventType";
import { StatusCode } from "../../../common/enums/StatusCode";
import { IRESTClient } from "../../../common/interfaces/IRestClient";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Response } from "../../../common/models/Response";
import moment from "moment";

export interface LogData {
    id: string;
    event_type: EventType;
    time_stamp: string;
    source: string;
    message: string;
}
type LogDataIndex = keyof LogData;

export interface AccessLoggingPageProps {
    client: IRESTClient
}

export enum LogTimeFrame {
    ONE_HOUR = 'ONE_HOUR',
    ONE_DAY = 'ONE_DAY',
    ONE_WEEK = 'ONE_WEEK',
    ONE_MONTH = 'ONE_MONTH'
}

export interface LogRequest {
    time_frame: LogTimeFrame
}

const AccessLoggingPage = (props: AccessLoggingPageProps) => {
    let {client} = props;
    let [loading, SetLoading] = useState(true);
    let [timeFrame, SetTimeFrame] = useState(LogTimeFrame.ONE_DAY);
    let [data, SetData] = useState<undefined | LogData[]>(undefined);
    const token = useSelector(GetUserIdToken);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    /**
     * Method that appends a unique key to the response item within the array of data using the id value from the original event.
     * @param response The response from the api call
     * @returns 
     */
    function BuildTableData(response: Response) {
        let events: LogData[] = [];
        for (let i in response.body) {
            let event = response.body[i];
            event.key = event.id;
            events.push(event);
        }
        return events;
    }

    const OnMenuClick = (e: any) => {
        SetLoading(true);
        SetTimeFrame(e.key);
        let request: LogRequest = {
            time_frame: e.key
        };

        client.GetChangeLogs(JSON.stringify(request), token!)
        .then((response) => {
            if(response.status === StatusCode.SUCCESS) {
                let events: LogData[] = BuildTableData(response);
                SetData(events);
                SetLoading(false);
            }
        })
        return;
    }

    const OnSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: LogDataIndex,
      ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    
    const OnReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    
    const GetColumnSearchProps = (dataIndex: LogDataIndex): ColumnType<LogData> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => OnSearch(selectedKeys as string[], confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button type="primary" onClick={() => OnSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>Search</Button>
              <Button onClick={() => clearFilters && OnReset(clearFilters)} size="small" style={{ width: 90 }}>Reset</Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: (visible: any) => visible? setTimeout(() => searchInput.current?.select(), 100) : () => {}
    });

    let menuItems: MenuProps['items'] = [
        {
            label: '1hour',
            key: LogTimeFrame.ONE_HOUR,
        },
        {
            label: '1day',
            key: LogTimeFrame.ONE_DAY,
        },
        {
            label: '1week',
            key: LogTimeFrame.ONE_WEEK,
        },
        {
            label: '1month',
            key: LogTimeFrame.ONE_MONTH,
        }
    ]

    let columns: ColumnsType<LogData> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width:'23.5%',
            ...GetColumnSearchProps('id'),
            render(value, record, index) {
                return <Typography.Text>{record.id}</Typography.Text>
            },
        },
        {
            title: 'Time',
            dataIndex: 'time_stamp',
            key: 'time_stamp',
            width:'25%',
            defaultSortOrder: 'descend',
            render: (value, record, index) => {
                return <Typography.Text>{new Date(Number(record.time_stamp) * 1000).toUTCString()}</Typography.Text>
            },
            sorter: (a, b) => moment(Number(a.time_stamp)).diff(moment(Number(b.time_stamp)))
        },
        {
            title: 'Event',
            dataIndex: 'event_type',
            key: 'event_type',
            width:'23.5%',
            filters: Object.values(EventType).map((eType) => {return { text: eType, value: eType}}),
            onFilter: (value, record) => value === record.event_type,
            render: (value, record, index) => {
                let colorMap = new Map<EventType, string>();
                colorMap.set(EventType.ConfigUpdate, 'lime');
                colorMap.set(EventType.FlagUpdate, 'magenta');
                colorMap.set(EventType.HolidayUpdate, 'purple');
                colorMap.set(EventType.HourUpdate, 'yellow');
                colorMap.set(EventType.PromptUpdate, 'green');
                colorMap.set(EventType.PromptUpload, 'orange');
                colorMap.set(EventType.UserUpdate, 'cyan');
                colorMap.set(EventType.ServerUpdate, 'gold');
                colorMap.set(EventType.Close, 'gray');
                colorMap.set(EventType.Ping, 'gray');
                colorMap.set(EventType.Open, 'gray');
                colorMap.set(EventType.Error, 'red');
                let color = colorMap.get(record.event_type);
                return <Tag color={color===undefined? 'geekblue' : color}>{record.event_type}</Tag>
            }
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            width:'25%',
            ...GetColumnSearchProps('source'),
            render(value, record, index) {
                return <Typography.Text>{record.source}</Typography.Text>
            },
        }
    ];

    useEffect(() => {
        if (data === undefined && token) {
            let request: LogRequest = {
                time_frame: timeFrame
            };

            client.GetChangeLogs(JSON.stringify(request), token)
            .then((response) => {
                if(response.status === StatusCode.SUCCESS) {
                    let events: LogData[] = BuildTableData(response);
                    SetData(events);
                    SetLoading(false);
                }
            })
            .catch((error) => {
                message.error(`Error fetching change logs: ${error}`);
            });
        }    
    }, [client , data, timeFrame, token]);
    
    const expandedRowRender = (e: LogData) => {
        return(
            <Form
            style={{margin:'2%', background: '#D6DBDF'}}
            layout='inline'>
                <Form.Item label="Id">
                    <Typography.Text code copyable >{e.id}</Typography.Text>
                </Form.Item>
                <Form.Item label="Source">
                    <Typography.Text code >{e.source}</Typography.Text>
                </Form.Item>
                <Form.Item style={{maxWidth:'50%'}} label="Message" >
                    <Typography.Text code copyable>{e.message}</Typography.Text>
                </Form.Item>
            </Form>);
    }

    return(
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{display:'flex', flexDirection:'row'}}>
                <Tooltip overlay='Refresh the data.'>
                    <Button type="link" icon={<ReloadOutlined/>} onClick={() => SetData(undefined)}>Refresh</Button>            
                </Tooltip>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Card
                    style={{width:'100%'}}
                    bordered={false}
                    // TODO: Wire up these buttons in a second pass to make the fetch response faster when it can be
                    // extra={
                    //     <Menu 
                    //         disabledOverflow={true}
                    //         onClick={OnMenuClick}
                    //         selectedKeys={[timeFrame]}
                    //         mode='horizontal'
                    //         items={menuItems}
                    //     />}
                    title="Change Logs">
                        <Table
                        loading={loading}
                        size="small"
                        bordered={true}
                        expandable={{expandedRowRender}}
                        pagination={{pageSizeOptions:[10, 20, 30, 40], position:['bottomRight'], showSizeChanger: true}}
                        dataSource={data} columns={columns}/>
                </Card>
            </div>
        </div>);
};

export default AccessLoggingPage;