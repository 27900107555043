import { Alert, Button, Card, Form, message } from "antd";
import { RESTClientWIP } from "../../../services/RESTClientWIP";
import { useDispatch, useSelector } from "react-redux";
import { GetUserIdToken } from "../../../../app/userSlice";
import { StatusCode } from "../../../../common/enums/StatusCode";
import { SetBuModalIsVisible, SetBuModalView } from "../../../../app/appSlice";
import { Response } from "../../../../common/models/Response";

export interface DeleteBusinessUnitModalViewProps {
    client: RESTClientWIP,
    businessUnitIds: any
}

const DeleteBusinessUnitModalView = (props: DeleteBusinessUnitModalViewProps) => {
    let { client, businessUnitIds } = props;
    const idToken = useSelector(GetUserIdToken);
    const dispatch = useDispatch();

    const OnFormComplete = async (values: any) => {
        if(idToken)  {
            let requests: Promise<Response>[] = [];
            Object.keys(businessUnitIds).forEach((bu) => {
                requests.push(client.DeleteBusinessUnit(bu, idToken!))
            });
           
            Promise.all(requests).then(responses => {
              responses.forEach((response, index) => {
                    if (response.status!==StatusCode.SUCCESS) {
                        message.error("One or more reqeuests failed!")
                    }
                });
            })
        } else {
            message.error("Invalid or expired token!")
        }
    }

    return(
        <Card title="Delete Business Unit(s)">
            <Form 
                colon
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={OnFormComplete}
                name="DeleteBusinessUnitForm">
                <Alert type='warning' message="Are you sure you wnant to delete the selected Business Unit(s) ? This operation cannot be undone!"/>
                <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                    <Button key='deleteBusinessUnitComplete' htmlType='submit' type='primary'>Delete</Button>
                </div>

            </Form>
        </Card>
    );
}

export default DeleteBusinessUnitModalView;