import { Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GetAppLanguage, SetApplicationLanguage } from "../../../app/appSlice";
import { GetCurrentTheme, GetThemeSecondaryTextColor, SetSecondaryTextColor, SetTheme, SetThemeBackground, SetThemeLinkColor, SetThemePrimaryColor, SetThemeSecondaryColor, SetThemeTextColor } from "../../../app/themeSlice";
import { Language } from "../../../common/enums/Language";
import { Theme } from "../../../common/enums/Theme";
import { ITheme } from "../../../common/interfaces/ITheme";
import { Themes } from "../../../common/models/Theme";
const { Option } = Select;

export interface AppSettingsPageProps {

}

const AppSettingsPage = (props: AppSettingsPageProps) => {
    const color = useSelector(GetThemeSecondaryTextColor);
    const currentLanguage = useSelector(GetAppLanguage);
    const currentTheme = useSelector(GetCurrentTheme);
    const dispatch = useDispatch();
    const UnpackTheme = (theme: ITheme) => {
        dispatch(SetThemeBackground(theme.background));
        dispatch(SetSecondaryTextColor(theme.secondaryTextColor));
        dispatch(SetThemeTextColor(theme.textColor));
        dispatch(SetThemeLinkColor(theme.linkColor));
        dispatch(SetThemePrimaryColor(theme.primaryColor));
        dispatch(SetThemeSecondaryColor(theme.secondaryColor));
    }

    const HandleThemeSelection = (selection: any) => {
        if(selection === Theme.DARK_MODE) {
            dispatch(SetTheme(Theme.DARK_MODE));
            UnpackTheme(Themes.DarkTheme)
        } else {
            UnpackTheme(Themes.DefaultTheme)
        }
    }
    return(
        <>
            <Form 
            layout="horizontal"
            name="appSettings"
            size="large">
                <Form.Item 
                key={1} 
                initialValue={currentTheme} 
                label={<label style={{ color: color, fontSize: 18 }}>Theme:</label>} 
                name='theme'>
                    <Select style={{ width: 220 }} onChange={HandleThemeSelection}>
                        <Option value={Theme.DEFAULT}>Default</Option>
                        <Option value={Theme.DARK_MODE}>Dark</Option>
                    </Select>
                </Form.Item>

                <Form.Item 
                key={2} 
                initialValue={currentLanguage} 
                label={<label style={{ color: color, fontSize: 18 }}>Language:</label>} 
                name='locale'>
                    <Select style={{ width: 220 }} onChange={(e) => dispatch(SetApplicationLanguage(e))}>
                    {Object.keys(Language).map( key => {
                        return process.env.REACT_APP_LANGUAGES?.split(', ').includes(key) ? <Option value={key}>{key}</Option> : <Option disabled value={key}>{key}</Option>
                    })}
                    </Select>
                </Form.Item>
            </Form>
        </>);
}

export default AppSettingsPage;