import { EditOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Input, InputRef, message, Space, Table, Tooltip } from "antd";
import { ColumnsType, ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAppFocusedTargetGroup, GetAppFocusedTargetGroups, IsDrawerOpen, SetDrawerOpen, SetFocusedTargetGroup, SetFocusedTargetGroups } from "../../../app/appSlice";
import { CacheConfig, GetCachedConfigs, InvalidateConfigCache, InvalidateFlagsCache, InvalidateHolidaysCache, InvalidateHoursCache, InvalidatePromptsCache } from "../../../app/cacheSlice";
import { GetUserIdToken, GetUserRoles, GetUserTargetGroups } from "../../../app/userSlice";
import { FormType } from "../../../common/enums/FormType";
import { Role } from "../../../common/enums/Role";
import { StandardAttributes } from "../../../common/enums/StandardAttributes";
import { StatusCode } from "../../../common/enums/StatusCode";
import { IRESTClient } from "../../../common/interfaces/IRestClient";
import { Response } from "../../../common/models/Response";
import CHConfigForm from "../CHCConfigForm";

export interface ErrorPageProps {
  client: IRESTClient
}


//** This component takes in an errorCode and Message */
const QueuesPage = (props: ErrorPageProps) => {
  const { client } = props;
  const dispatch = useDispatch();
  const idToken = useSelector(GetUserIdToken);
  const ConfigCache = useSelector(GetCachedConfigs);
  const targetGroups = useSelector(GetUserTargetGroups);
  const roles = useSelector(GetUserRoles);
  const focusedTargetGroup = useSelector(GetAppFocusedTargetGroup);
  const focusedTargetGroups = useSelector(GetAppFocusedTargetGroups);
  const [loadingData, SetLoadingData] = useState(false);
  const [dataSource, SetDataSource] = useState<any[]>([]);
  const drawerOpen = useSelector(IsDrawerOpen);
  const [activeTabKey, SetActiveKey] = useState<string>('flags');
  const [activeTabKeyMS, SetActiveKeyMS] = useState<string>('flags');
  const Tabs = [];
  const MultiSelectTabs = [];
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  
  interface QueueData {
    main: string;
    name: string;
    client: string;
    team: string;
    timezone: string;
    language: string;
  }

  type QueueIndex = keyof QueueData;

  const GetColumnSearchProps = (dataIndex: QueueIndex): ColumnType<QueueData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => OnSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => OnSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>Search</Button>
          <Button onClick={() => clearFilters && OnReset(clearFilters)} size="small" style={{ width: 90 }}>Reset</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });


  const OnSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: QueueIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const OnReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const OnTargetGroupClicked = (targetGroup : string) => {    
      dispatch(SetFocusedTargetGroup(targetGroup)); 
      dispatch(SetDrawerOpen(true));  
      let targetGroups: Record<string, string> = {};  
      targetGroups[targetGroup] =targetGroup;   
      dispatch(SetFocusedTargetGroups(targetGroups));
  };


  const columns: ColumnsType<QueueData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (left, right) => left.name?.localeCompare(right.name),
      render: (_: any, record: any) => <Button type="link" onClick={() => OnTargetGroupClicked(record.name)}>{record.name}</Button>,
      ...GetColumnSearchProps('name')
    },
    {
      title: 'Team',
      sorter: (left, right) => left.team?.localeCompare(right.team),
      dataIndex: StandardAttributes.TEAM,
      key: StandardAttributes.TEAM,
      ...GetColumnSearchProps('team')
    },
    {
      title: 'Language',
      sorter: (left, right) => left.language?.localeCompare(right.language),
      dataIndex: StandardAttributes.LANGUAGE,
      key: StandardAttributes.LANGUAGE,
      ...GetColumnSearchProps('language')
    }
  ];

  const contentRecords: Record<string, React.ReactNode> = {
    'config': <CHConfigForm type={FormType.CONFIG} client={client}/>,
    'flags': <CHConfigForm type={FormType.FLAGS} client={client}/>,
    'holidays': <CHConfigForm type={FormType.HOLIDAYS} client={client}/>,
    'hours': <CHConfigForm type={FormType.HOURS} client={client}/>,
    'prompts': <CHConfigForm type={FormType.PROMPTS} client={client}/>
  };

  const contentRecordsMultiSelect: Record<string, React.ReactNode> = {
    'flags': <CHConfigForm type={FormType.MULTISELECT_FLAGS} client={client} />,
    'hours': <CHConfigForm type={FormType.MULTISELECT_HOURS} client={client} />,
    'prompts': <CHConfigForm type={FormType.MULTISELECT_PROMPTS} client={client} />
  };

  Tabs.push({ tab: 'Flags', key: 'flags'});
  Tabs.push({ tab: 'Hours', key: 'hours'});
  Tabs.push({ tab: 'Holidays', key: 'holidays'});
  MultiSelectTabs.push({ tab: 'Flags', key: 'flags' });
  MultiSelectTabs.push({ tab: 'Hours', key: 'hours' });

  if(roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR))
    Tabs.push({ tab: 'Prompts', key: 'prompts'});
    MultiSelectTabs.push({ tab: 'Prompts', key: 'prompts' });

  if(roles?.includes(Role.DEVELOPER) || roles?.includes(Role.ADMINISTRATOR))
    Tabs.push({ tab: 'Config', key: 'config'});

  const ClearCache = () => {
    dispatch(InvalidateConfigCache());
    dispatch(InvalidateFlagsCache());
    dispatch(InvalidateHolidaysCache());
    dispatch(InvalidateHoursCache());
    dispatch(InvalidatePromptsCache());
    message.success("Refreshed");
  }

  useEffect(() => {
    if(targetGroups && idToken) {
      let requests: Promise<Response>[] = [];
      // loop over the assigned target groups and load he configs.
      targetGroups.forEach((targetGroup) => {
        // Check the queue for the data before we make another request
        if(!ConfigCache[targetGroup])
          requests.push(client.GetDefaultConfig(targetGroup, idToken));
      });

      // loop over the responses and cache the responses.
      SetLoadingData(true);
      Promise.all(requests)
        .then((responses) => {
          responses.forEach((response, index) => {
            if (response.status===StatusCode.SUCCESS) {
              let cacheEntry = {'name': targetGroups[index], "body":response.body}
              dispatch(CacheConfig(cacheEntry));
              SetLoadingData(false);
            } // TODO: Add 404 handling that disables this queue since there is no valid configuration - dan
          });

          //Loop the cached configurations and build the table data.
          let data: any[] = [];
          Object.entries(ConfigCache).map((queue) => {
            let _data: {[key: string]: string} =  {}
            let queueName = queue[0];
            let queueData = queue[1];
            Object.entries(queueData).map((description) => {
              let fieldName = description[0];
              let value = description[1];
              _data[fieldName] = value;
              return(<></>);
            });
            _data.key = queueName;
            _data.name = queueName;
            data.push(_data);
            return(<></>);
          })
          SetDataSource(data);
          SetLoadingData(false);
        })
        .catch((error) => {/*console.log(error)*/});
    }
  },[ConfigCache, client, dispatch, idToken, targetGroups]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: Object.keys(focusedTargetGroups),
    selectedRows: [focusedTargetGroups],
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      let targetGroups: Record<string, any> = {};
      selectedRows.map(row => {
        targetGroups[row.name] = row;
        return;
      });
      dispatch(SetFocusedTargetGroups(targetGroups));
    }
  };


  return(
    <>
      {drawerOpen &&
        <>
          {Object.keys(focusedTargetGroups).length > 1 &&
            <Drawer
              visible={true}
              title={`Editing multiple queues`}
              width={'55%'}
              onClose={() => dispatch(SetDrawerOpen(false))}
              bodyStyle={{ paddingBottom: 80 }}>
              <Card tabList={MultiSelectTabs} activeTabKey={activeTabKeyMS} onTabChange={(key) => SetActiveKeyMS(key)}>
                {contentRecordsMultiSelect[activeTabKeyMS]}
              </Card>
            </Drawer>
          }
          {Object.keys(focusedTargetGroups).length === 1 &&
            <Drawer
              visible={true}
              title={`Editing ${focusedTargetGroup}`}
              width={'55%'}
              onClose={() => dispatch(SetDrawerOpen(false))}
              bodyStyle={{ paddingBottom: 80 }}>
              <Card tabList={Tabs} activeTabKey={activeTabKey} onTabChange={(key) => SetActiveKey(key)}>
                {contentRecords[activeTabKey]}
              </Card>
            </Drawer>
          }
        </>}
        <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
          <div style={{display:'flex', flexDirection:'row'}}>
            <Tooltip overlay='Refresh the data.'>
              {/* exponential backoff/limit ? */}
              <Button type="link" icon={<ReloadOutlined/>} onClick={() => ClearCache()}>Refresh</Button>            
            </Tooltip>
            <Tooltip overlay='Select multiple items for bulk update'>            
              <Button icon={<EditOutlined/>} onClick={()=>dispatch(SetDrawerOpen(true))} disabled={Object.keys(focusedTargetGroups).length<2} type="link">Bulk Edit</Button>
            </Tooltip>
          </div>
          <Card
            style={{width:'100%'}}
            title={'Queue Management'}>
              <Table
              loading={loadingData}
              size="small"
              bordered={false}
              pagination={{pageSizeOptions:[10, 20, 30, 40], position:['bottomRight'], showSizeChanger: true}}
              columns={columns}
              dataSource={dataSource}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}/>
        </Card>        
      </div>
    </>);
}

export default QueuesPage;