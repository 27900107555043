import { CaretDownFilled, MenuFoldOutlined, MenuOutlined, NotificationOutlined, QuestionOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IsSiderCollapsed, SetApplicationPage, SetSiderCollapsed } from "../../app/appSlice";
import { GetThemePrimaryColor, GetThemeTextColor } from "../../app/themeSlice";
import { GetUserName } from "../../app/userSlice";
import { ApplicationPage } from "../../common/enums/ApplicationPage";

export interface CHCHeaderProps {
    src?: string
    icon?: string
}

const CHCHeader = (props: CHCHeaderProps) => {
    const {src, icon} = props;
    const dispatch = useDispatch();
    const userMenuDropdownItems: ItemType[] | undefined = []
    const userName = useSelector(GetUserName);
    const siderCollapsed = useSelector(IsSiderCollapsed);
    const background = useSelector(GetThemePrimaryColor);
    const color = useSelector(GetThemeTextColor);
    const style: React.CSSProperties = {
        display: 'flex',
        flexDirection:'row',
        borderRadius: 3,
        margin: 3,
        background: background,
        color: color,
        flex: '1',
        padding: 3
    }
    const columnStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent:'space-evenly',
        flexDirection:'column',
        margin: 3
    }
    userMenuDropdownItems.push(
        {
            key:'editProfile', 
            label: (<Button disabled type='link' onClick={() => {} }>Edit profile</Button>)
        });

    userMenuDropdownItems.push(
        {
            key:'preferences', 
            label: (<Button disabled type='link' onClick={() => {} }>Preferences</Button>)
        });

    userMenuDropdownItems.push(
        {
            key:'signout', 
            label: (<Button disabled type='link' onClick={() => {} }>Sign Out</Button>)
        });
    const userMenuDropdown = (<Menu items={userMenuDropdownItems}/>);
    
    useEffect(() => {}, [color, background]);
    
    return(
        <div style={style}>

            <div style={{...columnStyle, flex: '1 10'}}>
                <div style={{...columnStyle, flexDirection:'row'}}>
                    {icon && <img width={31} src={icon} alt="CHCIcon"/>}
                    <img width={110} src={src} alt="Change Health Care SSP"/>
                </div>
            </div>

            <div style={{...columnStyle, flex: '10 0', fontSize:'28px', paddingLeft:'20px'}}>
                <div style={{...columnStyle, flexDirection:'row', justifyContent:'left'}}>
                    { siderCollapsed?
                        <Button size="large" icon={<MenuOutlined/>} onClick={() => dispatch(SetSiderCollapsed(!siderCollapsed))} type='link'/> :
                        <Button size="large" icon={<MenuFoldOutlined/>} onClick={() => dispatch(SetSiderCollapsed(!siderCollapsed))} type='link'/>}
                </div>
            </div>

            <div style={{...columnStyle, flex: '2 10', fontSize:'18px'}}>
                {userName && 
                <div style={{...columnStyle, flexDirection:'row'}}>
                    <Button size="large" icon={<QuestionOutlined/>} onClick={() => dispatch(SetApplicationPage(ApplicationPage.HELP))} style={{color:color}} type='text'/>
                    <Button size="large" icon={<NotificationOutlined/>} style={{color:color}} type='text'/>
                    <Dropdown overlay={userMenuDropdown}>
                        <Button size="large" icon={<UserOutlined/>} style={{color:color}} type='text'>
                            {userName?.substring(0, userName.indexOf('@'))}
                            <CaretDownFilled/>
                        </Button>
                    </Dropdown>
                </div>}
            </div>
        </div>);
}

export default CHCHeader;