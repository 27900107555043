export enum FormType {
    BOTS,
    CONFIG,
    FLAGS,
    PROMPTS,
    HOURS,
    HOLIDAYS,
    MULTISELECT_FLAGS,
    MULTISELECT_HOURS,
    MULTISELECT_PROMPTS,
    MULTISELECT_CONFIG
}