export enum Language {
    ENGLISH='english',
    SPANISH='spanish',
    ENGLISH_US='english_us',
    ENGLISH_GB='english_gb',
    ENGLISH_IN='english_in',
    ENGLISH_AU='english_au',
    SPANISH_US='spanish_us',
    ESPANOL_ES='espanol_es',
    SPANISH_ES='spanish_es',
    FRENCH_CA='french_ca',
    FRENCH_FR='french_fr',
    ITALIAN_IT='italian_it',
    JAPANESE_JP='japanese_jp',
    GERMAN_DE='german_de',
    KOREAN_KR='korean_kr'
}