import jwtDecode from "jwt-decode";
import { IRESTClient } from "../../common/interfaces/IRestClient";
import { IGroup } from "../../common/models/Group";
import { APIResponse, AuthResponse, AuthResponseV2, Response } from "../../common/models/Response";
import { Role } from "../../common/enums/Role";
import { StatusCode } from "../../common/enums/StatusCode";
import { IBusinessUnit } from "../../common/interfaces/IBusinessUnit";
import { IRole } from "../../common/interfaces/IRole";
import { IGroupV2 } from "../../common/interfaces/IGroup";
import { IUser } from "../../common/models/User";

export class RESTClientWIP implements IRESTClient {
    
    static INSTANCE: IRESTClient;
    static adminUtilsUri: string;
    static userMgmtUri: string;
    static authUri: string;

    private constructor(adminUtilsUri: string, authUri: string, userMgmtUri: string) {
        RESTClientWIP.adminUtilsUri = adminUtilsUri;
        RESTClientWIP.authUri = authUri;
        RESTClientWIP.userMgmtUri = userMgmtUri;
    }

    static Create(adminUtilsUri: string, authUri: string, userMgmtUri: string) { 
        if (RESTClientWIP.INSTANCE) {
            return RESTClientWIP.INSTANCE;
        }
        RESTClientWIP.INSTANCE = new RESTClientWIP(adminUtilsUri, authUri, userMgmtUri);
        return RESTClientWIP.INSTANCE;
    }

    async Authenticate(authCode: string): Promise<AuthResponse> {
        let result;
        if(process.env.REACT_APP_AUTH_TOKEN && process.env.NODE_ENV==='development') {
            let MOCK_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
            result = {
                "access_token": MOCK_TOKEN,
                "refresh_token": MOCK_TOKEN,
                "id_token": MOCK_TOKEN,
                "token_type": "Bearer",
                "expires_in":3600
            }
        } else {
            // Make API Call and try to parse the response
            let uri = RESTClientWIP.authUri.concat('/oauth2/token');
            let request = {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: new URLSearchParams({
                    grant_type: "authorization_code",
                    code: authCode,
                    redirect_uri: process.env.REACT_APP_REDIRECT!,
                    client_id: process.env.REACT_APP_REST_CLIENT_ID!
                })
            }

            const response = await fetch(uri, request);
            if(!response.ok)
                return new AuthResponse(StatusCode.BAD_REQUEST, '', [], '', '', '', [])

            result = await response.json();            
        }

        try {
            // Parse token for roles, target group and user name
            var parsedToken: any = jwtDecode(result.id_token);
            let username: string = parsedToken['cognito:username']
            let targetGroups: string[] = [];
            let roles: Role[] = [];

            // Call authenticated user end point to get the users id
            // Parse the roles from the response 
            let authenticatedUserResponse = (await this.GetAuthenticatedUserData(result.id_token)).body;
            let userId = authenticatedUserResponse["id"];
            let _roles: IRole[] = authenticatedUserResponse["roles"];

            /*TODO: Iterrate the permissions within the roles to render the ui in the second pass.
                For now we are just checking explicitly for the given id, we dont want to keep this solution
                because it will not allow for roles to be defined at the bu level and instead makes them instance spefic.
                The intent for this pass is to keep the solution backwards compatible. - dan */
            _roles.forEach(r => {
                // console.log(JSON.stringify(r));
                if (r.id === process.env.REACT_APP_AGENT_ARN!) {
                    roles.push(Role.AGENT);
                }
                if (r.id === process.env.REACT_APP_DEVELOPER_ARN!) {
                    roles.push(Role.DEVELOPER);
                }
                if (r.id === process.env.REACT_APP_SUPERVISOR_ARN!) {
                    roles.push(Role.SUPERVISOR);
                }
                if (r.id === process.env.REACT_APP_ADMINISTRATOR_ARN!) {
                    roles.push(Role.ADMINISTRATOR);
                }
            })

            // Call to the user mamangement API to get the users busines units
            let businessUnits: IBusinessUnit[] = (await this.GetUserBusinessUnits(userId, result.id_token)).body;

            // Call to the user management API to get the users groups
            let groupResponse: IGroupV2[] = (await this.GetUserGroups(userId, result.id_token)).body;
            groupResponse.forEach(g => {
                targetGroups.push(g.name);
            })

            // Build and return the auth response object
            return new AuthResponseV2(
                StatusCode.SUCCESS,
                username,
                userId,
                targetGroups,
                businessUnits,
                groupResponse,
                result.id_token,
                result.id_token,
                result.id_token,
                roles)
        } catch (error) {
            return new AuthResponseV2(StatusCode.UNAUTHORIZED, '', '', [], [], [], '', '', '', [])
        }
    }

    async GetHours(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/hours');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetHolidays(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/holidays');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetDispositions(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/dispositions');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetPrompts(targetGroup: string, locale: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/prompts/').concat(locale);

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
            .then(response => response.json())
            .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetDynamicFlags(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/flags');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async GetDefaultConfig(targetGroup: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/config');

        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        
        apiResponse.body = response;
        return apiResponse;
    }

    async GetChangeLogs(value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/logs');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }
        /* TODO: Add response handling to the other API Calls in here - dan */
        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        return apiResponse;
    }
    
    async SetHours(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/hours');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async SetHolidays(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/holidays');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async SetDispositions(targetGroup: string, key: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/dispositions/').concat(key);

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async SetPrompt(targetGroup: string, locale: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/prompts/').concat(locale);

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }
        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async UploadPromptWav(targetGroup: string, locale: string, value: any, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/prompts/').concat(locale);

        let request = {
            method: 'POST',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: JSON.stringify(value)
        }
        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['errorMessage'];
        } catch (error) {
            try {
                apiResponse.errorMessage = apiResponse.body['message'];
            } catch (error) {
                // no valid error message could be parsed from the response body.
            }
        }
        return apiResponse;
    }

    async SetDynamicFlag(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/flags');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }
    
    async SetDefaultConfig(targetGroup: string, value: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.adminUtilsUri.concat('/').concat(targetGroup).concat('/config');

        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: value
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});

        apiResponse.body = response;
        return apiResponse;
    }

    async Delete(key: string): Promise<Response> {
        let response = new APIResponse(StatusCode.SUCCESS)
        return response;
    }

    //region User Management
    async GetAuthenticatedUserData(token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetUserGroups(userId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/user/').concat(userId).concat('/group');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetUserBusinessUnits(userId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/user/').concat(userId).concat('/businessUnit');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetBusinessUnitUsers(businessUnitId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId).concat('/users');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetBusinessUnitGroups(businessUnitId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId).concat('/groups');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async GetBusinessUnitRoles(businessUnitId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId).concat('/roles');
        let request = {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization' : 'Bearer '.concat(token)}
        }

        const response = await fetch(uri, request)
        .then(response => response.json())
        .then(data => {return data});
        apiResponse.body = response;
        return apiResponse;
    }

    async InviteUserToBusinessUnit(token: string, businessUnitId: string, value: any): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId).concat('/invite');
        let request = {
            method: 'POST',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token), 'Content-Type': 'application/json'},
            body: JSON.stringify(value)
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    async RemoveUserFromBusinessUnit(token: string, businessUnitId: string, user: IUser): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId).concat('/user/').concat(user.id!);
        let request = {
            method: 'DELETE',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    async GetGroups(token: string): Promise<Response> {
        throw new Error("Method not supported.");
    }

    async GetUsers(token: string): Promise<Response> {
        throw new Error("Method not supported.");
    }

    async CreateGroup(group: IGroup, token: string, businessUnitId: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId).concat('/group');

        let request = {
            method: 'POST',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token), 'Content-Type': 'application/json'},
            body: JSON.stringify(group)
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    async AddGroup(group: IGroup, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/group');

        let request = {
            method: 'POST',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
            body: JSON.stringify(group)
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['errorMessage'];
        } catch (error) {
            try {
                apiResponse.errorMessage = apiResponse.body['message'];
            } catch (error) {
                // no valid error message could be parsed from the response body.
            }
        }
        return apiResponse;
    }

    async AddUserToRole(roleId: string, userId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/role/').concat(roleId).concat('/user/').concat(userId);
        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    async RemoveUserFromRole(roleId: string, userId: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/role/').concat(roleId).concat('/user/').concat(userId);
        let request = {
            method: 'DELETE',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    async CreateBusinessUnit(businessUnit: any, token:string): Promise<Response> {
        //TODO: Update the API such that it adds the user who created the business unit at the time of creation
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit');
        let request = {
            method: 'POST',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token), 'Content-Type': 'application/json'},
            body: JSON.stringify(businessUnit)
        }

        const response = await fetch(uri, request);
        apiResponse.setStatus(response.status);
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['errorMessage'];
        } catch (error) {
            try {
                apiResponse.errorMessage = apiResponse.body['message'];
            } catch (error) {
                // no valid error message could be parsed from the response body.
            }
        }
        return apiResponse;
    }

    async DeleteBusinessUnit(businessUnitId: string, token:string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/businessUnit/').concat(businessUnitId);
        let request = {
            method: 'DELETE',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }
    
    async DeleteGroup(group: IGroupV2, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/group/').concat(group.id);
        let request = {
            method: 'DELETE',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    RemoveGroup(group: IGroup, token: string): Promise<Response> {
        throw new Error("Method not implemented.");
    }

    UpdateGroup(group: IGroup, token: string): Promise<Response> {
        throw new Error("Method not implemented.");
    }

    async AddUserToGroup(user: string, group: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/group/').concat(group).concat('/user/').concat(user);
        let request = {
            method: 'PUT',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }

    async RemoveUserFromGroup(user: string, group: string, token: string): Promise<Response> {
        let apiResponse = new APIResponse(StatusCode.SUCCESS);
        let uri = RESTClientWIP.userMgmtUri.concat('/group/').concat(group).concat('/user/').concat(user);
        let request = {
            method: 'DELETE',
            headers: {'Accept': '*/*', 'Authorization' : 'Bearer '.concat(token)},
        }

        const response = await fetch(uri, request)
        apiResponse.setStatus(response.status)
        apiResponse.body = await response.json();
        try {
            apiResponse.errorMessage = apiResponse.body['error'];
        } catch (error) {
            // no valid error message could be parsed from the response body.
        }
        return apiResponse;
    }
    //endregion
   

}