import { DeleteOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, message, Modal, Spin, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CacheGroups, GetCachedBusinessUnits, GetCachedGroups, GetCachedUsers, InvalidateConfigCache, InvalidateGroupsCache, InvalidateUsersCache } from "../../../app/cacheSlice";
import { GetUserIdToken, GetUserRoles } from "../../../app/userSlice";
import { StatusCode } from "../../../common/enums/StatusCode";
import { IRESTClient } from "../../../common/interfaces/IRestClient";
import { Group } from "../../../common/models/Group";
import { User } from "../../../common/models/User";
import { Response } from "../../../common/models/Response";
import { RESTClientWIP } from "../../services/RESTClientWIP";
import { IGroupV2 } from "../../../common/interfaces/IGroup";
import { IBusinessUnit } from "../../../common/interfaces/IBusinessUnit";
import { Role } from "../../../common/enums/Role";
import { GetAppBuModalIsVisible, GetAppBuModalView, GetAppFocusedBusinessUnits, SetBuModalIsVisible, SetBuModalView, SetFocusedBusinessUnit, SetFocusedBusinessUnits } from "../../../app/appSlice";
import EditBusinessUnitModelView from "./views/EditBusinesUnitModalView";
import { ModelView } from "../../../common/enums/ModelView";
import CreateBusinessUnitModalView from "./views/CreateBusinessUnitModalView";
import DeleteBusinessUnitModalView from "./views/DelteBusinessUnitModalView";

export interface UserPageProps {
    client: IRESTClient
}

/**
 * Users Page Component
 * @param props Prop definitions
 * @returns 
 */
const UsersPage = (props: UserPageProps) => {
    let { client } = props;
    const dispatch = useDispatch();
    const idToken = useSelector(GetUserIdToken);
    const [loadingData, SetLoadingData] = useState(true);
    const roles = useSelector(GetUserRoles);
    const UserCache = useSelector(GetCachedUsers);
    const GroupCache = useSelector(GetCachedGroups);
    const modelIsVisible = useSelector(GetAppBuModalIsVisible);
    const modelView = useSelector(GetAppBuModalView);
    const BusinessUnitsCache = useSelector(GetCachedBusinessUnits);
    const focusedBusinessUnits = useSelector(GetAppFocusedBusinessUnits);
    const [requestPending, SetRequestPending] = useState<boolean>(false);
    const [businessUnitDataSource, SetBusinessUnitDataSoure] = useState<any[]>([]);
    const { Text } = Typography;

    // Row Selection Logic for Users Page
    const userPageRS = {
        selectedRowKeys: Object.keys(focusedBusinessUnits),
        selectedRows: [focusedBusinessUnits],
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        let businessUnits: Record<string, any> = {};
        selectedRows.map(row => {
            businessUnits[row.id] = row;
            return;
        });
        dispatch(SetFocusedBusinessUnits(businessUnits));
        }
    };

    const businessUnitColumns: ColumnsType<IBusinessUnit> = [
        {
            title: 'Business Unit name',
            dataIndex: 'name',
            key: 'name',
            sorter: (left, right) => left.name?.localeCompare(right.name),
            render: (_, record) => <Button type="link" onClick={() => {dispatch(SetFocusedBusinessUnit(record.id)); dispatch(SetBuModalView(ModelView.EDIT_BUSINESS_UNIT)); dispatch(SetBuModalIsVisible(true)); }}>{record.name}</Button>
        },
        {
            title: 'Business Unit ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (left, right) => left.name?.localeCompare(right.id),
            render: (_, record) => <Text copyable>{record.id}</Text>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

    /**
     * Helper method to invalide the local user cache.
     * This will force the application to fetch the data
     */
    function InvalidateLocalCache() {
        dispatch(InvalidateConfigCache());
        dispatch(InvalidateGroupsCache());
        dispatch(InvalidateUsersCache());
    }

    /**
     * This helper method takes in a cache and builds the data arrays for the tables to display.
     * @param cache The User or Group cache to build the data from
     * @returns 
     */
    function BuildTableData(cache: { [key: string]: Group | User | IBusinessUnit}): any[] {
        let data: any = [];
        Object.entries(cache).forEach((entry) => {
            let _data: { [key: string]: string } = {}
            let entryKey = entry[0];
            let entryData = entry[1];
            Object.entries(entryData).forEach((entryData) => {
                let name = entryData[0];
                let value = entryData[1];
                _data[name] = value;
            })
            _data.key = entryKey;
            data.push(_data);
        });
        SetLoadingData(false);
        return data;
    }

    useEffect(() => {
        if (idToken) {

            // Build the Business Units Data
            if ( Object.keys(BusinessUnitsCache).length !== 0) {
                SetBusinessUnitDataSoure(BuildTableData(BusinessUnitsCache));
            }

            // Check the local cache for entries before we build the table
            if (Object.keys(GroupCache).length === 0) {
                SetLoadingData(true);
                try {
                    let _client = client as RESTClientWIP;
                    let requests: Promise<Response>[] = [];
                    Object.keys(BusinessUnitsCache).forEach((businessUnitUUID) => {
                        requests.push(_client.GetBusinessUnitGroups(businessUnitUUID, idToken));
                    });
                    Promise.all(requests)
                    .then((responses) => {
                        responses.forEach((response) => {
                            if (response.status !== StatusCode.SUCCESS) {
                                message.error("Unable to fetch groups.");
                                return;
                            }
                            // Iterate the response data and cache the entries 
                            let groupDataResponse: IGroupV2[] = response.body;
                            groupDataResponse.forEach((group) => {
                                let cacheEntry = { 'name': group.name, 'body': group};
                                dispatch(CacheGroups(cacheEntry));
                            })
                        });
                      })
                      .catch((error) => {
                        message.error(`Unable to fetch user.${error}`)
                      });
                } catch (error) {
                    message.error(`Unable to fetch groups.${error}`);
                }
            }

        }
    }, [client, idToken, UserCache, GroupCache, dispatch, BusinessUnitsCache]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <Tooltip overlay='Refresh the data.'>
                    <Button type="link" icon={<ReloadOutlined />} onClick={() => InvalidateLocalCache()}>Refresh</Button>
                </Tooltip>
                {roles?.includes(Role.DEVELOPER) &&
                <Tooltip overlay='Delete A Business Unit'>
                    <Button danger disabled={!(Object.keys(focusedBusinessUnits).length > 0)} type="link" icon={<DeleteOutlined />} onClick={() => { dispatch(SetBuModalView(ModelView.DELETE_BUSINESS_UNIT)); dispatch(SetBuModalIsVisible(true)); }}>Delete</Button>
                </Tooltip>}
                {roles?.includes(Role.DEVELOPER) &&
                <Tooltip overlay='Create Business Unit'>
                    <Button type="link" icon={<PlusOutlined />} onClick={() => { dispatch(SetBuModalView(ModelView.CREATE_BUSINESS_UNIT)); dispatch(SetBuModalIsVisible(true)); }}>Create Business Unit</Button>
                </Tooltip>}
            </div>
            <Modal
                width={'80%'}
                footer={false}
                closable={true}
                destroyOnClose
                onCancel={() => { dispatch(SetBuModalView(undefined)); dispatch(SetBuModalIsVisible(false)) }}
                visible={modelIsVisible}>
                <Spin
                    spinning={requestPending}>
                    <Card>
                        {modelView === ModelView.DELETE_BUSINESS_UNIT && <DeleteBusinessUnitModalView client={client as RESTClientWIP} businessUnitIds={focusedBusinessUnits} />}
                        {modelView === ModelView.EDIT_BUSINESS_UNIT && <EditBusinessUnitModelView client={client as RESTClientWIP}/>}
                        {modelView === ModelView.CREATE_BUSINESS_UNIT && <CreateBusinessUnitModalView client={client as RESTClientWIP}/>}
                    </Card>
                </Spin>
            </Modal>
            <Card
                title={'Business Unit, User and Group Management'}
                bordered={false}>
                <Table
                    loading={loadingData}
                    size="small"
                    bordered={true}
                    pagination={{ pageSizeOptions: [10, 20, 30, 40], position: ['bottomRight'], showSizeChanger: true }}
                    columns={businessUnitColumns}
                    dataSource={businessUnitDataSource}
                    rowSelection={{
                        type: "checkbox",
                        ...userPageRS,
                      }}/>
            </Card>
        </div>);
}

export default UsersPage;