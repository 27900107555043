export enum CardView {
    ADD_ROLE_TO_USER,
    REMOVE_ROLE_FROM_USER,
    REMOVE_USER_FROM_GROUP,
    ADD_USER_TO_GROUP,
    INVITE_USER,
    EDIT_USER,
    REMOVE_USER,
    CREATE_GROUP,
    EDIT_GROUP,
    REMOVE_GROUP,
}