import { Button, Card, Table, Typography, message } from "antd";
import { RESTClientWIP } from "../../../services/RESTClientWIP";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { GetUserIdToken } from "../../../../app/userSlice";
import { useEffect, useState } from "react";
import { IGroupV2 } from "../../../../common/interfaces/IGroup";
import { IBusinessUnit } from "../../../../common/interfaces/IBusinessUnit";

export interface RemoveUserFromGroupModalViewProps {
    client: RESTClientWIP,
    businessUnit: IBusinessUnit,
    users: string[]
}

const RemoveUserFromGroupModalView = (props: RemoveUserFromGroupModalViewProps) => {
    const {client, businessUnit, users } = props;
    const token = useSelector(GetUserIdToken);
    const [groupDataSource, SetGroupDataSource] = useState<any[]>([]);
    const [focusedGroups, SetFocusedGroups] = useState<Record<string, IGroupV2>>({});
    const [loading, SetLoading] = useState(false);

    const { Text } = Typography;

    const GroupRowSelection = {
        selectedRowKeys: Object.keys(focusedGroups),
        selectedRows: [focusedGroups],
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            let groups: Record<string, any> = {};
            selectedRows.map(row => {
                groups[row.id] = row;
                return;
            });
            SetFocusedGroups(groups);
        }
    };

    const groupColumns: ColumnsType<IGroupV2> = [
        {
            title: 'Group name',
            dataIndex: 'name',
            key: 'groupName',
            sorter: (left, right) => left.name?.localeCompare(right.name),
        },
        {
            title: 'Group ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (left, right) => left.name?.localeCompare(right.id),
            render: (_, record) => <Text copyable>{record.id}</Text>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true
        }
    ];

    function InjectUniqueKey(data: any) {
        return {...data, key: data['id']};
    }

    const RemoveUsersFromGroups = () => {
        SetLoading(true);
        try {
            users.forEach(u => {
                Object.keys(focusedGroups).forEach(g => {
                    client.RemoveUserFromGroup(u, g, token!);
                })
            })
            message.success("Users removed from group successfully");
            SetLoading(false);
        } catch (error) {
            message.error("Failed to remove users from groups!");
            SetLoading(false);
        }
    }

    useEffect(() => {
        let data: any[] = [];
        businessUnit.businessUnitGroups.forEach(bu => {
            data.push(InjectUniqueKey(bu));
        })
        SetGroupDataSource(data);
    },[]);

    return(
        <Card 
            loading={loading}
            extra={<Button danger type='primary' disabled={Object.entries(focusedGroups).length<1} onClick={RemoveUsersFromGroups}>Remove from group(s)</Button>}
            title={businessUnit.name}>
            <p>
                Select the group(s) you would like to remove these users from.
            </p>
            <Table
            rowSelection={ {
                type: "checkbox",
                ...GroupRowSelection,
            }}
            pagination={{ pageSizeOptions: [10, 20, 30, 40], position: ['bottomRight'], showSizeChanger: true }}
            columns={groupColumns}
            dataSource={groupDataSource}/>
        </Card>
    );
}

export default RemoveUserFromGroupModalView;