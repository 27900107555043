import { DeleteOutlined, DownOutlined, MinusOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { InputRef, Input, Space, Button, Card, Form, Table, Typography, Drawer, message, Dropdown, Menu, Tag } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { ColumnType, ColumnsType } from "antd/lib/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAppBuEditorActiveTab, GetAppFocusedBusinessUnit, SetAppBuEditorActiveTab } from "../../../../app/appSlice";
import { SSPTabs } from "../../../../common/enums/SSPTabs";
import { StatusCode } from "../../../../common/enums/StatusCode";
import { IBusinessUnit } from "../../../../common/interfaces/IBusinessUnit";
import { IGroupV2 } from "../../../../common/interfaces/IGroup";
import { IRole } from "../../../../common/interfaces/IRole";
import { IUser } from "../../../../common/models/User";
import { Response } from "../../../../common/models/Response";
import { RESTClientWIP } from "../../../services/RESTClientWIP";
import { GetCachedBusinessUnits } from "../../../../app/cacheSlice";
import { GetUserIdToken, GetUserRoles } from "../../../../app/userSlice";
import { CardView } from "../../../../common/enums/CardView";
import AddUserModalView from "./AddUserModalView";
import CreateGroupModalView from "./CreateGroupModalView";
import AddRoleToUserModalView from "./AddRoleToUserModalView";
import AddUserToGroupModalView from "./AddUserToGroupModalView";
import RemoveUserFromGroupModalView from "./RemoveUserFromGroupModalView";
import { Role } from "../../../../common/enums/Role";

export interface EditBusinessUnitModalViewProps {
    client: RESTClientWIP
}

const EditBusinessUnitModelView = (props: EditBusinessUnitModalViewProps) => {
    let {client} = props;
    let activeTab = useSelector(GetAppBuEditorActiveTab);
    let BusinessUnitsCache = useSelector(GetCachedBusinessUnits);
    let focusedBusinessUnit = useSelector(GetAppFocusedBusinessUnit);
    let businessUnit: IBusinessUnit = BusinessUnitsCache[focusedBusinessUnit!];
    const { Text } = Typography;

    const tabList = [{ tab: 'Users', key: SSPTabs.USERS }, { tab: 'Groups', key: SSPTabs.GROUPS }];
    const dispatch = useDispatch();
    const idToken = useSelector(GetUserIdToken);
    const roles = useSelector(GetUserRoles);
    const [loadingData, SetLoadingData] = useState(true);
    const [userDataSource, SetUserDataSource] = useState<any[]>([]);
    const [groupDataSource, SetGroupDataSource] = useState<any[]>([]);
    const [roleDataSource, SetRoleDataSource] = useState<any[]>([]);
    const [focusedGroups, SetFocusedGroups] = useState<Record<string, IGroupV2>>({});
    const [focusedUsers, SetFocusedUsers] = useState<Record<string, IUser>>({});
    const [drawerOpen, SetDrawerOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [cardView, SetCardView] = useState<CardView>(CardView.EDIT_USER);

    const searchInput = useRef<InputRef>(null);

    type UserIndex = keyof IUser;

    const GetColumnSearchProps = (dataIndex: UserIndex): ColumnType<IUser> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => OnSearch(selectedKeys as string[], confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button type="primary" onClick={() => OnSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>Search</Button>
                <Button onClick={() => clearFilters && OnReset(clearFilters)} size="small" style={{ width: 90 }}>Reset</Button>
              </Space>
            </div>
          ),
          filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          onFilter: (value, record) =>
            record[dataIndex]!
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase()),
          onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          }
    });

    /**
     * Helper method to build the table data format from a given object.
     * @param data 
     * @returns 
     */
    function InjectUniqueKey(data: any) {
        return {...data, key: data['id']};
    }

    const RemoveUsersFromBusinessUnit = () => {
       let requests: Promise<Response>[] = [];
        Object.values(focusedUsers).forEach((u) => {
            requests.push(client.RemoveUserFromBusinessUnit(idToken!, businessUnit.id, u));
        });
        Promise.all(requests).then((responses) => {
            responses.forEach((response) => {
                if(response.status===StatusCode.SUCCESS) {
                    message.success("User removed successfully!")
                }
            });
        });
    }

    const RemoveGroupsFromBusinessUnit = () => {
        let requests: Promise<Response>[] = [];
        Object.values(focusedGroups).forEach((g) => {
            requests.push(client.DeleteGroup(g, idToken!));
        });
        Promise.all(requests).then((responses) => {
            responses.forEach((response) => {
                if(response.status===StatusCode.SUCCESS) {
                    message.success("Group removed successfully!")
                }
            });
        });
    }

    const UserExtras = () => {
        const menu = (
            <Menu
                items={[
                {
                    key: '1',
                    label: (<Button disabled={Object.entries(focusedUsers).length<1} onClick={() => { SetCardView(CardView.ADD_USER_TO_GROUP); SetDrawerOpen(true)}} type="link">Add user(s) to a group</Button>),
                    icon: <PlusOutlined/>,
                },
                {
                    key: '4',
                    label: (<Button disabled={Object.entries(focusedUsers).length<1} onClick={() => { SetCardView(CardView.ADD_ROLE_TO_USER); SetDrawerOpen(true)}} type="link">Add role to user(s)</Button>),
                    icon: <PlusOutlined/>,
                },
                {
                    key: '2',
                    label: (<Button disabled={Object.entries(focusedUsers).length<1} onClick={() => { SetCardView(CardView.REMOVE_USER_FROM_GROUP); SetDrawerOpen(true)}} danger type="link">Remove user(s) from a group</Button>),
                    icon: <MinusOutlined/>,
                },
                {
                    key: '3',
                    label: (<Button disabled={Object.entries(focusedUsers).length<1} onClick={RemoveUsersFromBusinessUnit} danger type="link">Remove users(s) from Business Unit</Button>),
                    icon: <MinusOutlined/>,
                }
                ]}
            />
        );
        return(
            <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                <Dropdown overlay={menu}>
                    <Button type="primary" onClick={e => e.preventDefault()}>
                        <Space>Actions<DownOutlined /></Space>
                    </Button>
                </Dropdown>
            </div>);
    };

    const GroupExtras = () => {
        const menu = (
            <Menu
                items={[
                {
                    key: '1',
                    label: (<Button onClick={() => {SetCardView(CardView.CREATE_GROUP); SetDrawerOpen(true)}} type="link">Create Group</Button>),
                    icon: <PlusOutlined/>,
                },
                {
                    key: '4',
                    label: (<Button disabled={Object.entries(focusedGroups).length<1} onClick={RemoveGroupsFromBusinessUnit} danger type="link">Delete Group</Button>),
                    icon: <DeleteOutlined/>,
                }]}
            />
        );
        return(
            <>
                <Dropdown overlay={menu}>
                    <Button type="primary" onClick={e => e.preventDefault()}>
                        <Space>Actions<DownOutlined /></Space>
                    </Button>
                </Dropdown>
                
            </>);
    };

    const groupColumns: ColumnsType<IGroupV2> = [
        {
            title: 'Group name',
            dataIndex: 'groupName',
            key: 'groupName',
            ellipsis: true,
            sorter: (left, right) => left.name?.localeCompare(right.name),
            render: (_, record) => <Button type="link" onClick={() => {SetFocusedGroups({record})}}>{record.name}</Button>
        },
        {
            title: 'Group ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (left, right) => left.name?.localeCompare(right.id),
            render: (_, record) => <Text copyable>{record.id}</Text>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true
        }
    ];

    const roleColumns: ColumnsType<IRole> = [
        {
            title: 'Role name',
            dataIndex: 'name',
            key: 'name',
            sorter: (left, right) => left.name?.localeCompare(right.name)
        },
        {
            title: 'Role ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (left, right) => left.name?.localeCompare(right.id),
            render: (_, record) => record.id
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ]

    const RemoveRoleFromUser = async (roleId: string, userId: string) => {
        let response = await client.RemoveUserFromRole(roleId, userId, idToken!);
        if (response.status===StatusCode.SUCCESS) {
            message.success("Role sucessfully removed from the user!");
        } else {
            message.error("Something went wrong when removing the role from the selected user!");
        }
    }

    const userColumns: ColumnsType<IUser> = [
        {
            title: 'UserName',
            dataIndex: 'userName',
            key: 'userName',
            sorter: (left, right) => left.userName?.localeCompare(right.userName),
            ...GetColumnSearchProps('userName')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ellipsis: true,
            key: 'email'
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            render: (_, record) => <>{record.roles!.map(r => <Tag color="geekblue" key={r.id} closable={roles!.includes(Role.ADMINISTRATOR)} onClose={() => RemoveRoleFromUser(r.id, record.id!)}>{r.name}</Tag>)}</>,
        },
        {
            title: 'Number of Groups',
            dataIndex: 'businessUnitGroups',
            key: 'groups',
            render: (_, record) => <>{Object.entries(record.userGroups!).length}</>,
        }
    ];

    const OnSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: UserIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const OnReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const UserRowSelection = {
        selectedRowKeys: Object.keys(focusedUsers),
        selectedRows: [focusedUsers],
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            let users: Record<string, any> = {};
            selectedRows.map(row => {
                users[row.id] = row;
                return;
            });
            SetFocusedUsers(users);
        }
    };

    const GroupRowSelection = {
        selectedRowKeys: Object.keys(focusedGroups),
        selectedRows: [focusedGroups],
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            let groups: Record<string, any> = {};
            selectedRows.map(row => {
                groups[row.id] = row;
                return;
            });
            SetFocusedGroups(groups);
        }
    };

    useEffect(() => {
        let _client = client as RESTClientWIP;
        _client.GetBusinessUnitUsers(businessUnit.id, idToken!)
            .then((response) => {
                if(response.status === StatusCode.SUCCESS) {
                    let buUsers = response.body as IUser[];
                    let buGroups = businessUnit.businessUnitGroups as IGroupV2[];
                    let buRoles = businessUnit.roles as IRole[];

                    buUsers = buUsers.map((u) => {
                        return InjectUniqueKey(u);
                    })

                    buGroups = buGroups.map((g) => {
                        return InjectUniqueKey(g)
                    })

                    buRoles = buRoles.map((r) => {
                        return InjectUniqueKey(r);
                    })

                    SetUserDataSource(buUsers);
                    SetGroupDataSource(buGroups);
                    SetRoleDataSource(buRoles);
                    SetLoadingData(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },[
        idToken,
        businessUnit.businessUnitGroups,
        businessUnit.id,
        client,
        businessUnit.roles,
        activeTab]);
    
    return(
        <Card
            loading={loadingData}
            title={businessUnit.name}
            bordered={false}>
                <Drawer
                visible={drawerOpen}
                title={`Edit`}
                width={'55%'}
                onClose={() => SetDrawerOpen(false)}
                bodyStyle={{ paddingBottom: 80 }}>
                    {drawerOpen && cardView === CardView.ADD_ROLE_TO_USER && <AddRoleToUserModalView client={client} users={Object.keys(focusedUsers)}/>}
                    {drawerOpen && cardView === CardView.ADD_USER_TO_GROUP && <AddUserToGroupModalView client={client} businessUnit={businessUnit} users={Object.keys(focusedUsers)}/>}
                    {drawerOpen && cardView === CardView.REMOVE_USER_FROM_GROUP && <RemoveUserFromGroupModalView client={client}  businessUnit={businessUnit} users={Object.keys(focusedUsers)}/>}
                    {drawerOpen && cardView === CardView.INVITE_USER && <AddUserModalView client={client} businessUnitId={businessUnit.id}/>}
                    {drawerOpen && cardView === CardView.CREATE_GROUP && <CreateGroupModalView client={client}  businessUnitId={businessUnit.id}/>}
                </Drawer>
                <div style={{display: 'flex', flexDirection:'row', justifyContent:'right', marginBottom:'1%'}}>
                    <Button icon={<PlusOutlined/>} onClick={() => { SetCardView(CardView.INVITE_USER); SetDrawerOpen(true)}} type="primary">Invite User to Business Unit</Button>
                </div>
                <Card
                    title={"General Info"}
                    bordered>
                    <Form 
                        layout='horizontal'>
                            <FormItem label='Business Unit ID'>
                                <Text copyable>{businessUnit.id}</Text>
                            </FormItem>
                            <FormItem label='Business Unit name'>
                                <Text strong>{businessUnit.name}</Text>
                            </FormItem>
                            <FormItem label='Description'>
                                <Text>{businessUnit.description}</Text>
                            </FormItem>
                            <FormItem label='Estimated number of groups'>
                                <Text strong>{businessUnit.businessUnitGroups.length}</Text>
                            </FormItem>
                            <FormItem label='Estimated number of users'>
                                <Text strong>{userDataSource.length}</Text>
                            </FormItem>
                            {/* <FormItem label='Estimated number of roles'>
                                <Text strong>{businessUnit.roles.length}</Text>
                            </FormItem> */}
                    </Form>
                </Card>
                <Card 
                    extra={activeTab === SSPTabs.USERS ? <UserExtras/> : <GroupExtras/>}
                    bordered
                    onTabChange={(key) => dispatch(SetAppBuEditorActiveTab(key))}
                    activeTabKey={JSON.stringify(activeTab)}
                    tabProps={{type:'card'}}
                    tabList={tabList}>
                <Table
                    size="small"
                    bordered={false}
                        rowSelection={activeTab === SSPTabs.USERS ? {
                        type: "checkbox",
                        ...UserRowSelection,
                    } : {
                        type: "checkbox",
                        ...GroupRowSelection,
                    }}
                    pagination={{ pageSizeOptions: [10, 20, 30, 40], position: ['bottomRight'], showSizeChanger: true }}
                    columns={activeTab === SSPTabs.USERS ? userColumns : activeTab === SSPTabs.GROUPS ? groupColumns : roleColumns }
                    dataSource={activeTab === SSPTabs.USERS ? userDataSource : activeTab === SSPTabs.GROUPS ? groupDataSource : roleDataSource }/>
                </Card>
        </Card>);
}

export default EditBusinessUnitModelView;