import { createSlice } from '@reduxjs/toolkit';
import { ApplicationPage } from '../common/enums/ApplicationPage';
import { ApplicationStatus } from '../common/enums/ApplicationStatus';
import { Language } from '../common/enums/Language';
import { RootState } from './store';
import { IBusinessUnit } from '../common/interfaces/IBusinessUnit';
import { IUser } from '../common/models/User';
import { IGroupV2 } from '../common/interfaces/IGroup';
import { SSPTabs } from '../common/enums/SSPTabs';
import { ModelView } from '../common/enums/ModelView';

export interface AppState {
  language: Language | string,
  page: ApplicationPage;
  previousStatus: ApplicationStatus;
  status: ApplicationStatus;
  siderCollapsed: boolean;
  buModalIsVisible: boolean;
  buModalView: ModelView;
  focusedTargetGroup?: string;
  focusedTargetGroups: Record<string,string>;
  focusedBusinessUnit?: string;
  focusedBusinessUnits: Record<string, IBusinessUnit>;
  focusedUsers: Record<string, IUser>;
  focusedGroups: Record<string, IGroupV2>;
  drawerOpen: boolean;
  buEditorTab: SSPTabs;
}

const initialState: AppState = {
  language: "ENGLISH",
  page: ApplicationPage.LOADING,
  previousStatus: ApplicationStatus.LOADING,
  status: ApplicationStatus.LOADING,
  buModalIsVisible: false,
  buModalView: ModelView.EDIT_BUSINESS_UNIT,
  siderCollapsed: false,
  drawerOpen: false,
  focusedTargetGroups: {},
  focusedUsers: {},
  focusedGroups: {},
  focusedBusinessUnits: {},
  buEditorTab: SSPTabs.USERS
};

export const appSlice = createSlice({
  name: 'ssp',
  initialState,
  reducers: {
    SetBuModalView: (state, action) => {state.buModalView = action.payload },
    SetBuModalIsVisible: (state, action) => {state.buModalIsVisible = action.payload},
    SetAppBuEditorActiveTab: (state, action) => {state.buEditorTab = action.payload},
    SetApplicationLanguage: (state, action) => {state.language = action.payload; },
    SetApplicationPage: (state, action) => { state.page = action.payload; },
    SetApplicationStatus: (state, action) => { state.previousStatus = state.status; state.status = action.payload; },
    SetSiderCollapsed: (state, action) => { state.siderCollapsed = action.payload; },
    SetDrawerOpen: (state, action) => { state.drawerOpen = action.payload; },
    SetFocusedTargetGroup: (state, action) => { state.focusedTargetGroup = action.payload; },
    SetFocusedTargetGroups: (state, action) => { state.focusedTargetGroups = action.payload; },
    SetFocusedBusinessUnit: (state, action) => { state.focusedBusinessUnit = action.payload },
    SetFocusedBusinessUnits: (state, action) => { state.focusedBusinessUnits = action.payload },
    SetFocusedGroups: (state, action) => { state.focusedGroups = action.payload },
    SetFocusedUsers: (state, action) => { state.focusedUsers = action.payload },
    RemoveFromFocusedTargetGroups: (state, action) => { delete(state.focusedTargetGroups[action.payload]); },
  },
});

export const GetAppBuModalView = (state: RootState) => state.application.buModalView;
export const GetAppBuModalIsVisible = (state: RootState) => state.application.buModalIsVisible;
export const GetAppBuEditorActiveTab = (state: RootState) => state.application.buEditorTab;
export const GetAppLanguage = (state: RootState) => state.application.language;
export const IsDrawerOpen = (state: RootState) => state.application.drawerOpen;
export const GetAppPage = (state: RootState) => state.application.page;
export const GetAppStatus = (state: RootState) => state.application.status;
export const GetAppFocusedTargetGroup = (state: RootState) => state.application.focusedTargetGroup;
export const GetAppFocusedTargetGroups = (state: RootState) => state.application.focusedTargetGroups;
export const GetAppFocusedGroups = (state: RootState) => state.application.focusedGroups;
export const GetAppFocusedUsers = (state: RootState) => state.application.focusedUsers;
export const IsSiderCollapsed = (state: RootState) => state.application.siderCollapsed;
export const GetAppFocusedBusinessUnits = (state: RootState) => state.application.focusedBusinessUnits;
export const GetAppFocusedBusinessUnit = (state: RootState) => state.application.focusedBusinessUnit;

export const {
  SetBuModalView,
  SetBuModalIsVisible,
  SetAppBuEditorActiveTab,
  SetFocusedBusinessUnit,
  SetFocusedBusinessUnits,
  SetFocusedGroups,
  SetFocusedUsers,
  SetFocusedTargetGroups,
  SetApplicationLanguage,
  SetDrawerOpen,
  SetApplicationPage,
  SetFocusedTargetGroup,
  SetApplicationStatus,
  SetSiderCollapsed,
  RemoveFromFocusedTargetGroups
} = appSlice.actions;

export default appSlice.reducer;
