import { createSlice } from '@reduxjs/toolkit';
import { ConnectionStatus } from '../common/enums/ConnectionStatus';
import { RootState } from './store';

export interface ConnectionState {
  status: ConnectionStatus,
  previousStatus: ConnectionStatus,
}

const initialState: ConnectionState = {
  status: ConnectionStatus.CONNECTING,
  previousStatus: ConnectionStatus.DISCONNECTED,
};

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    SetConnectionStatus: (state, action) => { state.previousStatus = state.status; state.status = action.payload; }
  },
});

export const GetConnectionStatus = (state: RootState) => state.connection.status;
export const GetPreviousConnectionStatus = (state: RootState) => state.connection.previousStatus;

export const {
  SetConnectionStatus,
} = connectionSlice.actions;

export default connectionSlice.reducer;
