import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { GetThemeBackground, GetThemePrimaryColor, GetThemeSecondaryTextColor } from "../../../app/themeSlice";

export interface LoadingPageProps {
}

//** This component takes in an errorCode and Message */
const LoadingPage = (props: LoadingPageProps) => {
    const style: React.CSSProperties = {
        display: 'flex',
        flexDirection:'row',
        justifyContent:'space-evenly',
        textAlign: 'center',
        background: useSelector(GetThemeBackground),
        color: useSelector(GetThemeSecondaryTextColor)
    }

    return(
        <div style={style}>
            <div style={{...style, flexDirection:'column', justifyContent:'center', minHeight:'100vh'}}>
                <div style={{fontSize:'72px', color: useSelector(GetThemePrimaryColor)}}>
                    <Spin spinning indicator={<LoadingOutlined/>} tip="Loading the Self Service Portal..." size="large"/>
                </div>
            </div>
        </div>);
}

export default LoadingPage;