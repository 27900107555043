import { Color } from "../enums/Color";
import { Theme } from "../enums/Theme";
import { ITheme } from "../interfaces/ITheme";

//** Default mode theme template */
export class DefaultTheme implements ITheme {
    name = Theme.DEFAULT;
    background = Color.WHITE;
    primaryColor = Color.CHC_BLUE;
    textColor = Color.WHITE;
    secondaryTextColor = Color.BLACK;
}

//** Dark mode theme template */
export class DarkTheme implements ITheme {
    name = Theme.DARK_MODE;
    background = Color.CHC_GREYEST;
    primaryColor = Color.CHC_GREY;
    textColor = Color.WHITE;
    secondaryTextColor = Color.WHITE;
}

export class Themes {
    public static DarkTheme: ITheme = new DarkTheme();
    public static DefaultTheme: ITheme = new DefaultTheme();
}