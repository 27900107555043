import { Alert, Button, Form, Input, message } from "antd";
import { RESTClientWIP } from "../../../services/RESTClientWIP"
import FormItem from "antd/lib/form/FormItem";
import { useSelector } from "react-redux";
import { GetUserIdToken } from "../../../../app/userSlice";
import { StatusCode } from "../../../../common/enums/StatusCode";

export interface AddUserModalViewProps {
    client: RESTClientWIP,
    businessUnitId: string
}

const AddUserModalView = (props: AddUserModalViewProps) => {
    const idToken = useSelector(GetUserIdToken);
    const { client, businessUnitId } = props;
    const OnFinish = async (e: any) => {
        let response = await client.InviteUserToBusinessUnit(idToken!, businessUnitId, e )
        if(response.status !== StatusCode.SUCCESS) {
            message.error(response.errorMessage);
        } else {
            message.success("User invited successfully!");
        }
    }

    return(
        <>
            <Form
                colon
                onFinish={OnFinish}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal">
                <Alert type='info' message='User the form below to invite a valid user to this business unit.'/>
                <FormItem  required label="Email" name="email">
                    <Input width={'90%'}/>
                </FormItem>
                <Button type="primary" htmlType="submit">Submit</Button>
            </Form>
        </>
    );
}

export default AddUserModalView;