import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import connectionReducer from './connectionSlice';
import userReducer from './userSlice';
import themeReducer from './themeSlice';
import cacheReducer from './cacheSlice';

export const store = configureStore(
  {
    reducer: {
      application: appReducer,
      connection: connectionReducer,
      user: userReducer,
      theme: themeReducer,
      cache: cacheReducer,
    },
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
